import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../../utils/AuthUtils/AuthUtils";


const PrivateRouter = ({ element }) => {
    return isAuthenticated() ? element : <Navigate to="/ETPL_Admin_Login" state={{ message: "Please Login to continue" }}/>;
  };

export default PrivateRouter;
