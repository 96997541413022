import "./FooterCss/Footer.css";
import fb from "../../images/gmail.png";
import ins from "../../images/in.png";
import lin from "../../images/i.png";
import what from "../../images/wa.png";
import { Link } from "react-router-dom";
import LOgo from "../../images/Logo.png";

function Footer() {
  return (
    <>
      <footer className="footer line-height p-3 md:p-5 border-t-2 ">
        <div className="container">
          <div className="footer-row flex items-center justify-evenly gap-5 md:flex-row flex-col">
            <div className="footer-col  flex items-center justify-center gap-4 flex-col text-center footer-col-4 min-h-[250px] w-[350px]">
              <img src={LOgo} alt="" className="w-[90%]" />
              <h2>EMAYAM TECHNOLOGIES PRIVATE LIMITED</h2>
              <br />
            </div>
            <div className="footer-col  footer-col-2 text-center flex flex-col items-center justify-center min-h-[250px] w-[350px] ">
              <h2 className="text-pink-600 text-center">LINKS</h2>
              <ul className="leading-loose">
                <li>
                  <Link to="/" className="hover:text-green-600">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/About" className="hover:text-green-600">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/Services" className="hover:text-green-600">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/Resources" className="hover:text-green-600">
                    Resources
                  </Link>
                </li>
                <li>
                  <Link to="/Career" className="hover:text-green-600">
                    Careers
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-col  flex items-center justify-center flex-col footer-col-3 min-h-[250px] md:w-[350px] w-[100%] ">
              {/* <h2 className='text-pink-600 text-center'>CONTACT US</h2>
              <ul>
                <li><Link to='tel:+91 8111 066 066' className='whitespace-nowrap'><span className='text-violet-500'>Ph No :</span> +91 8111 066 066</ Link ></li>
                <li><Link to='mailto:sales@emayamtech.com' className='whitespace-nowrap'><span className='text-violet-500'>Email :</span> sales@emayamtech.com</ Link ></li>
                <li className='leading-loose'>
                  <span className='text-violet-600'>Address:</span>
                   <br />Emayam Towers, Bhuvaneshwari Nagar,
                  <br /> Velachery,. Chennai,
                  <br /> Tamil Nadu-600096, India
                </li>
              </ul> */}
              <p className="text-pink-600 text-center text-[20px] font-bold">
                Choose Emayamtech for seamless digital solutions
              </p>
              <br />
              <Link to="Contact" className="btn hover:text-white">
                Request a Quote
              </Link>
              <div className="mt-4 md:w-full">
                <h2 className="text-center md:text-left">CONNECT WITH US:</h2>
                <div className="social-links flex items-center justify-evenly mt-4">
                  <Link to='mailto:sales@emayamtech.com'>
                    <img src={fb} className="MediaIcons" alt=""></img>{" "}
                  </Link>
                  <Link to="https://www.linkedin.com/company/emayam/">
                    <img src={ins} className="MediaIcons" alt=""></img>
                  </Link>
                  <a href="tel:+918111066066">
                    <img src={what} className="MediaIcons" alt=""></img>{" "}
                  </a>
                  <Link to="/">
                    <img src={lin} className="MediaIcons" alt=""></img>{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="footer-col-1 ">
            <h2>CONNECT WITH US:</h2>
            <div className="social-links">
              <Link to="/">
                <img src={fb} className='MediaIcons' alt=""></img>{" "}
              </ Link >
              <Link to="https://www.linkedin.com/company/emayam/">
                <img src={ins} className='MediaIcons' alt=""></img>
              </ Link >
              <a href="tel:+918111066066">
                <img src={what} className='MediaIcons' alt=""></img>{" "}
              </ a >
              <Link to="/">
                <img src={lin} className='MediaIcons' alt=""></img>{" "}
              </ Link >
            </div>
          </div> */}
          <p className="copy-right p-4 mt-2 border-t-[1px]">
            Emayam Technologies Private Limited © 2023 - All Rights Reserved
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
