import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/16/solid";
import { GetPartnerdataAndAddPartnerdata, UpdatePartnerdataAndDeletePartnerdata } from "../../../../services/EtplApi";

export default function UpdatePartnerForm({ isvisible, handleClose, id,updatepartner }) {
  const [showmessage, setShowmessage] = useState(false);
  const [partnername, setPartnerName] = useState('');
  const [link, setLink] = useState('');
  const [imagefile, setImageFile] = useState(null); // Track image file
  const [imagePreview1, setImagePreview1] = useState(''); // Track image preview URL
  const [imagePreview2, setImagePreview2] = useState(''); // Track image preview URL
  const [imagechange,setImagechange] = useState(true);
  const BASE_URL = 'https://etplbe.etpl.cloud/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if all required fields are filled
    if (partnername && link) {
      const formdata = new FormData();
      formdata.append("Name",partnername);
      formdata.append("link", link);

      // Append image file if selected
      if (imagefile) {
        formdata.append("logo", imagefile);
      }

      // Make API call to update the latest news
      const response = await UpdatePartnerdataAndDeletePartnerdata('PATCH',id,formdata);
      updatepartner(response.data);
      handleClose(false); // Close the form after submitting
    } else {
      setShowmessage(true); // Show error message if fields are empty
    }
  };

  const getdata = async () => {
    const response = await GetPartnerdataAndAddPartnerdata('GET', {});
    const data = response.data.find(item => item.id === id);
    
    setPartnerName(data.Name);
    setLink(data.link);
    setImagePreview1(data.logo); // Set existing image for preview
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file); // Update image file in state
    setImagePreview2(URL.createObjectURL(file));// Create a preview URL for the selected image
    setImagechange(false)  
  };

  useEffect(() => {
    getdata(); // Fetch data when component loads or id changes
  }, [id]);

  const input = "bg-pink-200 px-5 py-2 w-[400px] rounded-2xl focus:outline-none focus:border-b-2 focus:border-pink-600 focus:placeholder-pink-400";

  return (
    <div  className="absolute top-0 left-0 w-full h-[100vh] z-50">
      {isvisible && (
        <div className=" h-full fixed w-full bg-black bg-opacity-60 flex justify-center items-center">
          <div className="bg-white border-t-4 border-b-4 border-pink-600 w-fit h-fit rounded-2xl p-5">
            <XMarkIcon
              onClick={() => handleClose(false)} // Close the form
              className="text-red-600 h-8 w-8 float-right text-3xl cursor-pointer hover:text-pink-600 hover:border-pink-600 border-2 border-red-600 rounded-full"
            />
            <form
              onSubmit={handleSubmit}
              className="text-pink-700 text-md grid-cols-1 grid space-y-1"
              encType="multipart/form-data"
            >
              <h1 className="text-center font-bold text-2xl mb-5">
                UPDATE PARTNER DETAILS
              </h1>
              <label className="text-xl">Partner Name:</label>
              <input
                name="Heading"
                onChange={(e) => setPartnerName(e.target.value)}
                value={partnername}
                className={input}
                type="text"
              />
              <label className="text-xl">Link:</label>
              <input
                name="link"
                onChange={(e) => setLink(e.target.value)}
                value={link}
                className={input}
                type="text"
              />
              <label className="text-xl">Partner Logo:</label>
              <div className="flex justify-center">
              {imagechange ?
                  (<img
                    src={`${BASE_URL}${imagePreview1}`}
                    alt="Uploaded Preview"
                    className="bg-pink-200 h-[150px] w-[150px] border-pink-600 border-dashed border-2 rounded-2xl justify-center flex"
                  />)
                  :
                  (<img
                    src={imagePreview2}
                    alt="Upload Preview"
                    className="bg-pink-200 h-[150px] w-[150px] border-pink-600 border-dashed border-2 rounded-2xl justify-center flex"
                    />)
              }
              </div>
              <input name="image" type="file" onChange={handleImageChange} />
              {showmessage && (
                <p className="text-lg text-red-600 mt-2">All the Fields are Required..</p>
              )}
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="border-pink-600 py-2 w-fit px-10 border-2 text-xl text-pink-600 rounded-3xl group hover:text-white hover:bg-pink-600"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}