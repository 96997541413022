import React, { useState, useEffect } from "react";
import PartnerCarousel from "./PartnerCarousel";
import { getAllOrAddTeamMember } from "../../../services/EtplApi";

function App() {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = "https://etplbe.etpl.cloud/";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllOrAddTeamMember("GET");
        setPartners(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  
  // Transform the API data to match the expected format
  const transformedPartners = partners.map(partner => {    
    return {
        image: `${BASE_URL}${partner.Image}`,
        name: partner.Name,
        role: partner.Position,
        link: partner.Link
    };
});

  return <PartnerCarousel partners={transformedPartners} />;
}

export default App;