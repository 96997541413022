import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// import { getAllOrAddNews } from "../../services/EtplApi";
import { newsData } from "../../utils/NewsUtils/NewsUtils";

const LatestNews = () => {
  const [news, setNews] = useState(newsData); // Using static data for now
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  // const BASE_URL = "https://etplbe.etpl.cloud/";
  const scrollRef = useRef(null);
  const containerRef = useRef(null);
  const scrollSpeedRef = useRef(1);
  const animationFrameRef = useRef(null);

  // ------------------ commented for dynamic fields -------------------

  // useEffect(() => {
  //   const fetchNews = async () => {
  //     try {
  //       const response = await getAllOrAddNews("GET");
  //       setNews(response.data);
  //     } catch (error) {
  //       console.error("Error fetching news:", error);
  //       setError("Failed to fetch news data. Please try again later.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchNews();
  // }, []);

  useEffect(() => {
    const container = containerRef.current;
    const scrollContent = scrollRef.current;

    if (!container || !scrollContent) return;

    // Check if scrolling should be enabled based on screen size and news length
    const enableScrolling = () => {
      const isMobile = window.innerWidth < 768; // md breakpoint
      return isMobile ? news.length > 1 : news.length > 2;
    };

    if (enableScrolling()) {
      // Clone content for infinite scrolling effect
      scrollContent.innerHTML += scrollContent.innerHTML;

      const scrollAnimation = () => {
        if (container.scrollLeft >= scrollContent.scrollWidth / 2) {
          container.scrollLeft = 0;
        }
        container.scrollLeft += scrollSpeedRef.current;
        animationFrameRef.current = requestAnimationFrame(scrollAnimation);
      };

      animationFrameRef.current = requestAnimationFrame(scrollAnimation);

      // Reverse scroll direction on hover
      const handleMouseEnter = () => {
        scrollSpeedRef.current = 1; // Slow reverse scrolling
      };
      const handleMouseLeave = () => {
        scrollSpeedRef.current = -0.5; // Normal forward scrolling
      };

      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        cancelAnimationFrame(animationFrameRef.current);
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [news]);

  // if (loading) return <div>Loading news...</div>;
  // if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="latest text-white line-height relative overflow-hidden w-full py-10">
      <h1
        data-aos="fade-up"
        className="text-pink-600 text-center text-2xl font-bold"
      >
        LATEST NEWS
      </h1>
      <p
        data-aos="fade-up"
        className="text-center max-w-2xl mx-auto mt-2 text-gray-600"
      >
        Stay updated with the latest news and updates from our team.
      </p>

      {/* Infinite Scrolling Wrapper */}
      <div
        className="news-box relative w-full mt-6 overflow-hidden"
        ref={containerRef}
      >
        <div className="flex items-center gap-10" ref={scrollRef}>
          {/* ---------------------- commented for backend retrieve ----------- */}

          {/* {news.map((item, index) => (
            <div className="flex-shrink-0" key={index}>
              <div className="md:w-[400px] p-2 w-[300px]">
                <Link to={item.link} target="_blank">
                  <img
                    src={`${BASE_URL}${item.image}`}
                    alt={item.heading}
                    className="w-full h-[200px] rounded-md hover:scale-[1.1] transition-all ease-in-out duration-300"
                  />
                </Link>
                <p className="mt-2">{item.created_at}</p>
                <Link to={item.link} target="_blank">
                  <h4 className="text-[19px] mt-2 font-bold text-pink-600">
                    {item.heading}
                  </h4>
                </Link>
                <br />
                <p>{item.description}</p>
              </div>
            </div>
          ))} */}

          {/* ---------------------------------------------------------------- */}

          {newsData.map((item) => (
            <div className="flex-shrink-0" key={item.id}>
              <div className="md:w-[400px] p-2 w-[300px]">
                <Link to={item.link} target="_blank">
                  <img
                    src={item.image}
                    alt={item.heading}
                    className="w-full h-[200px] rounded-md hover:scale-[1.1] transition-all ease-in-out duration-300"
                  />
                </Link>
                <p className="mt-2">{item.created_at}</p>
                <Link to={item.link} target="_blank">
                  <h4 className="text-[19px] mt-2 font-bold text-pink-600">
                    {item.heading}
                  </h4>
                </Link>
                <p className="mt-2">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;