import React, { useState, useEffect } from 'react';
import BannerImage from '../../images/back1.jpg';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Link } from 'react-router-dom';
import { getUpdateOrDeleteProfile } from '../../services/EtplApi';
import DeleteProfile from './DeleteProfile/DeleteProfile';
import SideNavbar from '../../components/AdminHeader/Side Navbar/SideNavbar';

const Profile = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userData, setUserData] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [buttonvisible, setButtonvisible] = useState(true);
    
    // Functions to handle sidebar opening and closing
    const shownavigation = () => {
        setIsSidebarOpen(true);
        setButtonvisible(false);
    };
   
    const hidenavigation = () => {
        setIsSidebarOpen(false);
        setButtonvisible(true);
    };

    const handleDeleteClick = () => {
        setShowDeletePopup(true);
    };
  
    const handleClosePopup = () => {
        setShowDeletePopup(false);
    };

    const UserId = localStorage.getItem("UserId");

    useEffect(() => {
        // Fetch user data from the API
        const fetchUserData = async () => {
            try {
                const response = await getUpdateOrDeleteProfile(UserId); // Assuming this function fetches the user data
                const data = response.data;

                // Convert the profile picture to a Base64 URL if it exists
                if (data.profile_picture) {
                    data.profile_picture = `data:image/jpeg;base64,${data.profile_picture}`;
                }

                setUserData(data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [UserId]);

    if (!userData) {
        return <div className='text-[32px] w-full h-[100vh] flex items-center justify-center text-white font-bold'>Loading...</div>; // Display a loading message while data is being fetched
    }

    return (
        <div className='w-full h-[100vh]'>
            <div className='flex items-center py-2 px-5'>
                <SideNavbar 
                    shownavigation={shownavigation} 
                    hidenavigation={hidenavigation} 
                    isSidebarOpen={isSidebarOpen}
                    isbuttonvisible={buttonvisible}
                />
            </div>
            <div className='w-full fixed top-0 -z-10'>
                <img src={BannerImage} className='w-full h-[100vh]' alt="" />
            </div>
            <div className='w-full flex items-center justify-evenly h-[80vh] z-10'>
                <div className='w-[300px] min-h-[60vh] border-t-4 border-pink-500 border-b-4 rounded-md bg-white flex items-center justify-center gap-3 flex-col'>
                    {/* Display the profile picture using Base64 data */}
                    {userData.profile_picture ? (
                        <img 
                            src={userData.profile_picture} 
                            alt="Profile" 
                            className='w-[80%] shadow-lg shadow-black h-[250px] rounded-full border-[1px] border-pink-600'
                        />
                    ) : (
                        <div className='w-[80%] shadow-lg shadow-black h-[250px] rounded-full border-[1px] border-pink-600 flex items-center justify-center bg-gray-200'>
                            <p className='text-pink-600 text-[20px]'>No Image</p>
                        </div>
                    )}
                    <p className='text-[25px] font-serif text-pink-600 font-bold'>{userData.user.first_name} {userData.user.last_name}</p>
                    <p className='text-[25px] font-serif'>{userData.position}</p>
                </div>
                <div className='bg-white relative border-t-4 border-pink-500 border-b-4 w-[650px] p-4 rounded-md min-h-[50vh]'>
                    <h1 className='text-center text-[25px] text-pink-500'>Welcome Back {userData.user.username}!!!</h1>
                    <MdOutlineDeleteOutline
                        className='absolute text-[25px] top-7 right-7 text-pink-600 cursor-pointer'
                        onClick={handleDeleteClick}
                    />
                    {showDeletePopup && (
                        <DeleteProfile
                            profileId={userData.user.id}
                            onClose={handleClosePopup}
                        />
                    )}
                    <div className='mt-4 flex flex-col gap-4'>
                        <div className='flex gap-5'>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label htmlFor="" className='text-pink-700 font-bold'>First Name:</label>
                                <input type="text" value={userData.user.first_name} className='bg-pink-200 cursor-not-allowed' disabled />
                            </div>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label htmlFor="" className='text-pink-700 font-bold'>Last Name:</label>
                                <input type="text" value={userData.user.last_name} className='bg-pink-200 cursor-not-allowed' disabled />
                            </div>
                        </div>
                        <div className='flex gap-5'>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label htmlFor="" className='text-pink-700 font-bold'>Gender</label>
                                <select name="" className='bg-pink-200 cursor-not-allowed' id="" disabled>
                                    <option value="M" selected={userData.gender === 'M'}>Male</option>
                                    <option value="F" selected={userData.gender === 'F'}>Female</option>
                                    <option value="O" selected={userData.gender === 'O'}>Other</option>
                                </select>
                            </div>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label htmlFor="" className='text-pink-700 font-bold'>Mobile Number:</label>
                                <input type="text" value={userData.phone_number} className='bg-pink-200 cursor-not-allowed' disabled />
                            </div>
                        </div>
                        <div className='flex gap-5'>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label htmlFor="" className='text-pink-700 font-bold'>Email:</label>
                                <input type="text" value={userData.user.email} className='bg-pink-200 cursor-not-allowed' disabled />
                            </div>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label htmlFor="" className='text-pink-700 font-bold'>User Name:</label>
                                <input type="text" value={userData.user.username} className='bg-pink-200 cursor-not-allowed' disabled />
                            </div>
                        </div>
                        <div className='mt-2 flex items-center justify-center'>
                            <Link 
                                to="/admin/Admin_Profile_Edit_page"
                                disabled={isSubmitting}
                                className={`bg-red-400 flex items-center justify-center p-2 w-[60%] font-serif font-bold rounded-lg transition-all duration-300 ease-in-out ${
                                    isSubmitting ? 'bg-gray-400 text-white cursor-not-allowed' : 'hover:bg-pink-700'
                                }`}
                            >
                                {isSubmitting ? 'Redirecting...' : 'Edit Profile'}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;