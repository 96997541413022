import React, { useState, useEffect } from "react";
import EditJob from "../forms/EditJob"; // Import the EditJob component
import { Link } from "react-router-dom";
import {
  getAllOrPostJob,
  getUpdateOrDeleteJob,
} from "../../../services/EtplApi";
import SideNavbar from "../../../components/AdminHeader/Side Navbar/SideNavbar";

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [editingJobId, setEditingJobId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [buttonvisible, setButtonvisible] = useState(true);

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await getAllOrPostJob("GET"); // Use the API service
      setJobs(response.data);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await getUpdateOrDeleteJob(id, "DELETE"); // Use the API service
      fetchJobs(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };

  const handleEdit = (id) => {
    setEditingJobId(id);
  };

  const handleCloseEdit = () => {
    setEditingJobId(null);
    fetchJobs(); // Refresh the list after editing
  };

  // Format date to "DD-MM-YYYY"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  // Functions to handle sidebar opening and closing
  const shownavigation = () => {
    setIsSidebarOpen(true);
    setButtonvisible(false);
  };

  const hidenavigation = () => {
    setIsSidebarOpen(false);
    setButtonvisible(true);
  };

  return (
    <div className="p-8 bg-black text-white">
      <SideNavbar
        shownavigation={shownavigation}
        hidenavigation={hidenavigation}
        isSidebarOpen={isSidebarOpen}
        isbuttonvisible={buttonvisible}
      />

      <h2 className="text-3xl font-bold text-pink-600 mb-8">Job Listings</h2>
      <div className=" absolute right-14  top-[9rem]">
        <Link
          to="/admin/Post_job_Offers"
          className="rounded-[30px] hover:bg-pink-500 hover:text-black font-bold transition-all ease-out duration-300 px-4 py-2 border-2 border-pink-500"
        >
          Post Job
        </Link>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-900 rounded-lg overflow-hidden">
          <thead className="bg-pink-600">
            <tr>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Title
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Description
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Qualification
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Key Skills
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Location
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Department
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                No. of Positions
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Employment Type
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Work Timing
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Experience
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Responsibility
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Posted On
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {jobs.map((job) => (
              <tr key={job.id} className="hover:bg-gray-800">
                <td className="px-6 py-4 text-sm text-gray-300 whitespace-nowrap">
                  {job.title}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 min-w-[400px]">
                  {job.description}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300">
                  {job.qualification}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300">
                  {job.Key_Skills}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 whitespace-nowrap">
                  {job.location}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 whitespace-nowrap">
                  {job.department}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 whitespace-nowrap">
                  {job.No_Of_Positions}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 whitespace-nowrap">
                  {job.employment_type}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 whitespace-nowrap">
                  {job.Work_timing}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 whitespace-nowrap">
                  {job.Experiance}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 min-w-[400px]">
                  {job.responsibility}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 whitespace-nowrap">
                  {formatDate(job.posted_on)}
                </td>
                <td className="px-6 py-4 text-sm whitespace-nowrap">
                  <button
                    onClick={() => handleEdit(job.id)}
                    className="mr-2 text-pink-600 hover:text-pink-700"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(job.id)}
                    className="text-red-600 hover:text-red-700"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {editingJobId && (
        <EditJob jobId={editingJobId} onClose={handleCloseEdit} />
      )}
    </div>
  );
};

export default JobList;
