import React, { useState } from 'react';
import { UpdatePartnerdataAndDeletePartnerdata } from '../../../../services/EtplApi';

export default function DeletePartnerForm({ isvisible, id, deletepartner, handleClose }) {
  const [loading, setLoading] = useState(false); // Track loading state
  const [error, setError] = useState(null); // Track error state

  const handleDelete = async () => {
    setLoading(true); // Set loading state to true while the request is in progress
    setError(null); // Reset any previous errors

    try {
      const response = await UpdatePartnerdataAndDeletePartnerdata('DELETE', id);
      
      // Update the parent state to remove the deleted item
      deletepartner(id);
      handleClose(false); // Close the modal
    } catch (error) {
      setError("Failed to delete the partner. Please try again."); // Handle any errors
      console.error("Error deleting partner:", error);
    } finally {
      setLoading(false); // Reset loading state after the request is done
    }
  };

  return (
    <div  className="absolute top-0 left-0 w-full h-[100vh] z-50">
      {/* Delete Partner Form */}
      {isvisible && (
        <div className="w-screen fixed h-screen bg-black bg-opacity-60 flex justify-center items-center">
          <div className="bg-white border-4 border-b-pink-600 border-t-pink-600 w-fit h-fit rounded-2xl p-5">
            <h2 className="text-xl text-pink-600 text-center font-bold mb-5">
              Are you sure you want to delete this partner?
            </h2>
            {error && <p className="text-red-600 text-center mb-3">{error}</p>} {/* Show error message */}
            <div className="flex gap-4 justify-end">
              <button
                onClick={handleDelete}
                className="relative px-4 py-3 text-lg font-medium  bg-red-600 border-2 border-red-600 rounded-3xl inline-flex justify-center items-center w-fit hover:bg-white hover:text-red-600"
                disabled={loading} // Disable button while loading
              >
                {loading ? 'Deleting...' : 'Confirm Delete'}
              </button>
              <button
                onClick={() => handleClose(false)} // Close the modal on Cancel
                className="relative px-4 py-3 text-lg font-medium  bg-green-600 border-2 border-green-600 rounded-3xl inline-flex justify-center items-center w-fit hover:bg-white hover:text-green-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}