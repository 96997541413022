import React, { useState } from "react";
import LogoImage from "../../../images/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { resetPassword } from "../../../services/EtplApi";
import { BsArrowBarLeft } from "react-icons/bs";

const ResetPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    new_password: "",
    confirm_password: "",
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");

    // Validate that the new password and confirm password match
    if (formData.new_password !== formData.confirm_password) {
      setErrorMessage("Passwords do not match.");
      setIsSubmitting(false);
      return;
    }

    try {
      // Call the resetPassword API
      const response = await resetPassword({
        new_password: formData.new_password,
        confirm_password: formData.confirm_password,
      });

      // Handle the response
      if (response) {
        // Optionally, you can redirect the user to a different page after a successful submission
        setSuccessMessage(response.message || "Password reset successfully!");
        setTimeout(() => {
          navigate("/ETPL_Admin_Login"); // Redirect to login page after 3 seconds
        }, 3000);
      } else {
        setErrorMessage(response.message || "Failed to reset password. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      console.error("Reset Password Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-black h-[100vh] w-full flex items-center justify-center flex-col gap-10">
      <div>
        <img src={LogoImage} alt="Logo" className="w-[280px] md:w-[320px]" />
      </div>
      <div>
        <form
          onSubmit={handleSubmit}
          className="bg-white relative border-t-4 border-t-red-500 border-b-4 border-b-red-600 p-3 rounded-md w-[300px] sm:w-[350px] md:w-[450px] min-h-[45vh]"
        >
          {/* Display success or error messages */}

          <h1 className="text-pink-600 text-[23px] mt-1 text-center tracking-wider font-bold font-serif">
            Reset Password
          </h1>
          
          {successMessage && (
            <div className="bg-green-100 mt-2 border text-center border-green-400 text-green-700 p-2 rounded mb-2">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="bg-red-100 mt-2 border text-center border-red-400 text-red-700 p-2 rounded mb-2">
              {errorMessage}
            </div>
          )}
          <div className="top-5 left-5 absolute ">
            <Link to="/ETPL_Admin_Login">
              <BsArrowBarLeft className="text-pink-600 text-[25px]" />
            </Link>
          </div>
          <div className="flex relative justify-center gap-2 mt-3 flex-col">
            <label htmlFor="new_password" className="text-pink-800">
              New Password:
            </label>
            <input
              type={showPassword ? "text" : "password"}
              name="new_password"
              placeholder="Enter New Password"
              value={formData.new_password}
              onChange={handleInputChange}
              className="bg-pink-200"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="text-pink-900 absolute top-[55px] right-3"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="flex relative justify-center gap-2 mt-2 flex-col">
            <label
              htmlFor="confirm_password"
              className="text-pink-800 whitespace-nowrap"
            >
              Confirm New Password:
            </label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              placeholder="Enter Your Confirm Password"
              value={formData.confirm_password}
              onChange={handleInputChange}
              className="bg-pink-200"
              required
            />
            <button
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="text-pink-900 absolute top-[55px] right-3"
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="mt-6 flex items-center justify-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`bg-red-400 w-[60%] py-2 font-serif font-bold rounded-lg transition-all duration-300 ease-in-out ${
                isSubmitting
                  ? "bg-gray-400 text-white cursor-not-allowed"
                  : "hover:bg-pink-700"
              }`}
            >
              {isSubmitting ? "Resetting..." : "Reset Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;