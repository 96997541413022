// src/components/Drawer.js
import React from "react";
import { Link } from "react-router-dom";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaUserTie,
  FaUsers,
  FaNewspaper,
  FaUserShield,
  FaBuilding,
} from "react-icons/fa";
import { IoSettings } from "react-icons/io5";
import { MdDashboard, MdContacts, MdLeaderboard } from "react-icons/md";
import { HiOfficeBuilding } from "react-icons/hi";

const SideNavbar = ({
  shownavigation,
  hidenavigation,
  isSidebarOpen,
  isbuttonvisible,
}) => {
  // Retrieve is_superuser from local storage
  const isSuperUser = localStorage.getItem("is_superuser") === "true";

  return (
    <div>
      {/* Button to open the drawer */}
      <div>
        <button
          onClick={shownavigation}
          className={`text-white bg-pink-600 hover:bg-pink-300 font-medium rounded-full text-sm p-2 ${
            isbuttonvisible ? "text-black bg-black" : ""
          }`}
          type="button"
        >
          <FaAngleDoubleRight className="lg:text-4xl md:text-lg" />
        </button>
      </div>

      {/* Drawer content */}
      <div
        className={`fixed text-white md:text-sm top-[11vh] left-0 z-40 h-[100vh] bg-red-600 rounded-br-3xl rounded-tr-3xl border-r-4 border-pink-600 p-5 transition-transform duration-500 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } bg-gray-300 dark:bg-gray-800`}
        tabIndex="-1"
      >
        <div className="absolute top-5 right-5">
          <span>
            <FaAngleDoubleLeft
              onClick={hidenavigation}
              className="md:text-2xl lg:text-3xl hover:text-pink-600 cursor-pointer"
            />
          </span>
        </div>

        {/* Drawer list items */}
        <div className="flex mt-3 items-center h-[77vh] flex-col justify-between">
          <ul className="space-y-7 font-medium">
            <li className="flex items-center">
              <MdDashboard className="text-pink-600 lg:text-xl md:text-lg" />
              <Link to="/admin/DashBoard">
                <span className="ms-3 hover:cursor-pointer hover:text-pink-400 md:text-[20px] lg:text-xl">
                  Admin Dashboard
                </span>
              </Link>
            </li>
            <li className="flex items-center">
              <IoSettings className="text-pink-600 lg:text-xl md:text-lg" />
              <Link to="/admin/Admin_Dashboard_Applications">
                <span className="flex-1 ms-3 hover:cursor-pointer hover:text-pink-400 md:text-[20px] lg:text-xl whitespace-nowrap">
                  Application Dashboard
                </span>
              </Link>
            </li>
            <li className="flex items-center">
              <MdContacts className="text-pink-600 lg:text-xl md:text-lg" />
              <Link to="/admin/Contact_Form_Dashboard">
                <span className="flex-1 ms-3 hover:cursor-pointer hover:text-pink-400 md:text-[20px] lg:text-xl whitespace-nowrap">
                  Contact Dashboard
                </span>
              </Link>
            </li>
            <li className="flex items-center">
              <FaUserTie className="text-pink-600 lg:text-xl md:text-lg" />
              <Link to="/admin/Admin_Job_List_view">
                <span className="flex-1 ms-3 hover:cursor-pointer hover:text-pink-400 md:text-[20px] lg:text-xl whitespace-nowrap">
                  Job Listing
                </span>
              </Link>
            </li>
            <li className="flex items-center">
              <FaUserShield className="text-pink-600 lg:text-xl md:text-lg" />
              <Link to="/admin/partnerCoursel">
                <span className="flex-1 ms-3 hover:cursor-pointer hover:text-pink-400 md:text-[20px] lg:text-xl whitespace-nowrap">
                  Partner Settings
                </span>
              </Link>
            </li>
            <li className="flex items-center">
              <FaNewspaper className="text-pink-600 lg:text-xl md:text-lg" />
              <Link to="/admin/latestNews">
                <span className="flex-1 ms-3 hover:cursor-pointer hover:text-pink-400 md:text-[20px] lg:text-xl whitespace-nowrap">
                  News Settings
                </span>
              </Link>
            </li>
            <li className="flex items-center">
              <MdLeaderboard className="text-pink-600 lg:text-xl md:text-lg" />
              <Link to="/admin/ourLeadership">
                <span className="flex-1 ms-3 hover:cursor-pointer hover:text-pink-400 md:text-[20px] lg:text-xl whitespace-nowrap">
                  Leadership Settings
                </span>
              </Link>
            </li>
            <li className="flex items-center">
              <HiOfficeBuilding className="text-pink-600 lg:text-xl md:text-lg" />
              <Link to="/">
                <span className="flex-1 ms-3 hover:cursor-pointer hover:text-pink-400 md:text-[20px] lg:text-xl whitespace-nowrap">
                  Client Page
                </span>
              </Link>
            </li>

            {/* Conditionally render the "View All Users" option */}
            {isSuperUser && (
              <li className="flex items-center">
                <FaUsers className="text-pink-600 lg:text-xl md:text-lg" />
                <Link to="/admin/Admin_Profile_Dashboard">
                  <span className="flex-1 ms-3 hover:cursor-pointer hover:text-pink-400 md:text-[20px] lg:text-xl whitespace-nowrap">
                    View All Users
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideNavbar;