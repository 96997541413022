import FiasiteImage from '../../images/png company profiles/Fiasite-removebg-preview.png'
import PowdsImage from '../../images/png company profiles/Powds_Corp-removebg-preview.png'
import EmartImage from '../../images/png company profiles/logo2-removebg-preview.png'
import TelzupImage from '../../images/png company profiles/telzup-removebg-preview.png'
import VConnectImage from '../../images/png company profiles/logo1.png'
import ZMMImage from '../../images/png company profiles/image.png'
import EmayamImage from '../../images/png company profiles/Emayam-Logo.png'

export const partners = [
  {
    id: 1,
    name: "Fiasite",
    link: "#",
    logo: FiasiteImage, // Use default if no logo is available
  },
  {
    id: 2,
    name: "Powds Corp",
    link: "#",
    logo: PowdsImage,
  },
  {
    id: 3,
    name: "emart",
    link: "#",
    logo: EmartImage,
  },
  {
    id: 4,
    name: "Telzup",
    link: "#",
    logo: TelzupImage,
  },
  {
    id: 5,
    name: "V Connect",
    link: "#",
    logo: VConnectImage,
  },
  {
    id: 6,
    name: "ZMM",
    link: "#",
    logo: ZMMImage,
  },
  {
    id: 7,
    name: "emayam",
    link: "#",
    logo: EmayamImage,
  },
];
