import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/16/solid";
import {
  GetOurLeadershipAndAddOurLeadership,
  UpdateOurLeadershipAndDeleteOurLeadership,
} from "../../../../services/EtplApi";
import './css/UpdateLeader.css';

export default function UpdateLeadershipForm({
  isvisible,
  handleClose,
  id,
  updateLeadership,
}) {
  const [showmessage, setShowmessage] = useState(false);
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [profilelink, setProfilelink] = useState("");
  const [imagefile, setImageFile] = useState(null); // Track image file
  const [imagePreview1, setImagePreview1] = useState(""); // Track image preview URL
  const [imagePreview2, setImagePreview2] = useState(""); // Track image preview URL
  const [imagechange, setImagechange] = useState(true);
  const BASE_URL = "https://etplbe.etpl.cloud/";

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if all required fields are filled
    if (name && position && profilelink) {
      const formdata = new FormData();
      formdata.append("Name", name);
      formdata.append("Position", position);
      formdata.append("Link", profilelink);

      // Append image file if selected
      if (imagefile) {
        formdata.append("Image", imagefile);
      }

      // Make API call to update the latest news
      const response = await UpdateOurLeadershipAndDeleteOurLeadership(
        "PATCH",
        id,
        formdata
      );
      updateLeadership(response.data);
      handleClose(false); // Close the form after submitting
    } else {
      setShowmessage(true); // Show error message if fields are empty
    }
  };

  const getdata = async () => {
    const response = await GetOurLeadershipAndAddOurLeadership("GET", {});
    const data = response.data.find((item) => item.id === id);

    setName(data.Name);
    setPosition(data.Position);
    setProfilelink(data.Link);
    setImagePreview1(data.Image); // Set existing image for preview
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file); // Update image file in state
    setImagePreview2(URL.createObjectURL(file)); // Create a preview URL for the selected image
    setImagechange(false);
  };

  const input =
    "bg-pink-200 px-5 py-2 w-[400px] rounded-2xl focus:outline-none focus:border-b-2 focus:border-pink-600 focus:placeholder-pink-400";

  useEffect(() => {
    getdata(); // Fetch data when component loads or id changes
  }, [id]);

  return (
    <div className="absolute top-0 left-0 w-full h-[100vh] z-50">
      {isvisible && (
        <div className=" bg-black fixed bg-opacity-60  w-full h-[100vh] flex justify-center items-center">
          <div className="bg-white relative border-t-4 border-b-4 border-pink-600 w-fit h-fit rounded-2xl p-4">
            <XMarkIcon
              onClick={() => handleClose(false)} // Close the form
              className="text-red-600 h-8 w-8 top-2 right-3 absolute text-3xl cursor-pointer hover:text-pink-600 hover:border-pink-600 border-2 border-red-600 rounded-full"
            />
            <h1 className="text-center text-pink-700   font-bold text-2xl p-2">
                UPDATE LEADERSHIP DETAILS
              </h1>
            <form
              onSubmit={handleSubmit}
              className="text-pink-700 p-2 LeaderForm  text-md max-h-[80vh] overflow-x-auto  grid-cols-1 grid space-y-1"
              encType="multipart/form-data"
            >
              <label className="text-xl">Name:</label>
              <input
                name="Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                className={input}
                type="text"
              />
              <label className="text-xl">Position:</label>
              <input
                name="Position"
                onChange={(e) => setPosition(e.target.value)}
                value={position}
                className={input}
                type="text"
              />
              <label className="text-xl">Profile Link:</label>
              <input
                name="profilelink"
                onChange={(e) => setProfilelink(e.target.value)}
                value={profilelink}
                className={input}
                type="text"
              />
              <label className="text-xl">Image:</label>
              <div className="flex justify-center">
                {imagechange ? (
                  <img
                    src={`${BASE_URL}${imagePreview1}`}
                    alt="Uploaded Preview"
                    className="bg-pink-200 h-[150px] w-[150px] border-pink-600 border-dashed border-2 rounded-2xl justify-center flex"
                  />
                ) : (
                  <img
                    src={imagePreview2}
                    alt="Upload Preview"
                    className="bg-pink-200 h-[150px] w-[150px] border-pink-600 border-dashed border-2 rounded-2xl justify-center flex"
                  />
                )}
              </div>
              <input name="image" type="file" onChange={handleImageChange} />
              {showmessage && (
                <p className="text-lg text-red-600 mt-2">
                  All the Fields are Required..
                </p>
              )}
              <span className="flex justify-center">
                <button
                  type="submit"
                  className="border-pink-600 py-2 w-[70%] transition-all duration-300 ease-in-out px-10 border-2 text-xl text-pink-600 rounded-3xl group hover:text-white hover:bg-pink-600"
                >
                  Update
                </button>
              </span>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
