import React, { useEffect, useState } from 'react';
import { GetOurLeadershipAndAddOurLeadership } from '../../../services/EtplApi';
import SideNavbar from '../../../components/AdminHeader/Side Navbar/SideNavbar';
import AddLeadershipForm from '../../../Pages/AdminDashboard/Forms/AddLeadershipForm/AddLeadershipForm';
import UpdateLeadershipForm from '../../../Pages/AdminDashboard/Forms/UpdateLeadershipForm/UpdateLeadershipForm';
import DeleteLeadershipForm from '../../../Pages/AdminDashboard/Forms/DeleteLeadershipForm/DeleteLeadershipForm';

export default function OurLeadershipTeam() {
    const BASE_URL = 'https://etplbe.etpl.cloud';
    const [ourLeadershipTeamdatas, setOurLearshipTeamDatas] = useState([]);
    const [isVisibleAddLeadershipForm, setIsVisibleAddLeadershipForm] = useState(false);
    const [isVisibleUpdateLeadershipForm, setIsVisibleUpdateLeadershipForm] = useState(false);
    const [isVisibleDeleteForm, setIsVisibleDeleteForm] = useState(false);
    const [leadershipId, setLeadershipId] = useState();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [buttonvisible, setButtonvisible] = useState(true);

    const OurLeadershiptTeam = async () => {
        const response = await GetOurLeadershipAndAddOurLeadership('GET', {}); // 'GET' method with empty object for no params
        setOurLearshipTeamDatas(response.data);
    };

    const addLeadership = (newData) => {
        setOurLearshipTeamDatas((prevData) => [...prevData, newData]);
    };

    const updateLeadership = (updatedData) => {
        setOurLearshipTeamDatas((prevData) =>
            prevData.map((item) => (item.id === updatedData.id ? updatedData : item))
        );
    };

    const deleteLeadership = (id) => {
        setOurLearshipTeamDatas((prevData) => prevData.filter((item) => item.id !== id));
    };

    const handleVisible = () => setIsVisibleAddLeadershipForm(true);
    const isAddhide = () => setIsVisibleAddLeadershipForm(false);

    const handleUpdateVisible = (e) => {
        setIsVisibleUpdateLeadershipForm(true);
        setLeadershipId(parseInt(e.target.id));
    };

    const isUpdatehide = () => setIsVisibleUpdateLeadershipForm(false);

    const handleDeleteVisible = (e) => {
        setIsVisibleDeleteForm(true);
        setLeadershipId(parseInt(e.target.id));
    };

    const isDeletehide = () => setIsVisibleDeleteForm(false);

    const shownavigation = () => {
        setIsSidebarOpen(true);
        setButtonvisible(false);
    };

    const hidenavigation = () => {
        setIsSidebarOpen(false);
        setButtonvisible(true);
    };

    useEffect(() => {
        OurLeadershiptTeam();
    }, []);

    return (
        <div className="min-h-screen bg-black">
            <div className="flex items-center justify-between w-full p-4 bg-black shadow-md">
                <SideNavbar shownavigation={shownavigation} hidenavigation={hidenavigation} isSidebarOpen={isSidebarOpen} isbuttonvisible={buttonvisible} />
                <div className={`text-3xl font-bold text-pink-600 transition-all duration-500 ${isSidebarOpen ? 'ml-48' : ''}`}>
                    LEADERSHIP MEMBERS
                </div>
                <button
                    onClick={handleVisible}
                    className="text-xl text-white bg-pink-600 border-2 border-pink-600 rounded-full px-6 py-2 hover:bg-black hover:text-pink-600 transition-all duration-300">
                    Add Leadership
                </button>
            </div>

            <div className={`p-4 transition-all duration-500 ${isSidebarOpen ? 'ml-48' : ''}`}>
                <div className="overflow-x-auto bg-gray-600 rounded-lg shadow-md">
                    <table className="min-w-full table-auto">
                        <thead className="bg-pink-600 text-white">
                            <tr>
                                <th className="px-4 py-3">S.NO.</th>
                                <th className="px-4 py-3">NAME</th>
                                <th className="px-4 py-3">POSITION</th>
                                <th className="px-4 py-3">PROFILE LINK</th>
                                <th className="px-4 py-3">IMAGE</th>
                                <th className="px-4 py-3">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody className="text-white">
                            {ourLeadershipTeamdatas.map((item, index) => (
                                <tr key={item.id} className="border-b hover:bg-gray-400 transition-all duration-200">
                                    <td className="px-4 py-3 text-center">{index + 1}</td>
                                    <td className="px-4 py-3 text-center">{item.Name}</td>
                                    <td className="px-4 py-3 text-center">{item.Position}</td>
                                    <td className="px-4 py-3 text-center max-w-[250px] break-words">
                                        <a href={item.Link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                            {item.Link}
                                        </a>
                                    </td>
                                    <td className="px-4 py-3 flex items-center justify-center">
                                        <img
                                            src={`${BASE_URL}${item.Image}`}
                                            alt={item.Name}
                                            className="w-16 h-16 object-cover rounded-full"
                                        />
                                    </td>
                                    <td className="px-4 py-3 text-center">
                                        <button
                                            id={item.id}
                                            onClick={handleUpdateVisible}
                                            className="mx-2 px-4 py-2 text-sm font-medium text-pink-600 border-2 border-pink-600 rounded-full hover:bg-pink-600 hover:text-white transition-all duration-300">
                                            Update
                                        </button>
                                        <button
                                            id={item.id}
                                            onClick={handleDeleteVisible}
                                            className="mx-2 px-4 py-2 text-sm font-medium text-red-600 border-2 border-red-600 rounded-full hover:bg-red-600 hover:text-white transition-all duration-300">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {isVisibleAddLeadershipForm && (
                <AddLeadershipForm isvisible={isVisibleAddLeadershipForm} handleClose={isAddhide} addLeadership={addLeadership} />
            )}
            {isVisibleUpdateLeadershipForm && (
                <UpdateLeadershipForm isvisible={isVisibleUpdateLeadershipForm} handleClose={isUpdatehide} id={leadershipId} updateLeadership={updateLeadership} />
            )}
            {isVisibleDeleteForm && (
                <DeleteLeadershipForm isvisible={isVisibleDeleteForm} id={leadershipId} handleClose={isDeletehide} deleteLeadership={deleteLeadership} />
            )}
        </div>
    );
}