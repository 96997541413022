import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/16/solid";
import { GetlatestAndAddlatestnews, UpdatelatestAndDeletelatestnews } from "../../../../services/EtplApi";

export default function UpdateLatestNewsForm({ isvisible, handleClose, id, updateNews }) {
  const [showmessage, setShowmessage] = useState(false);
  const [heading, setHeading] = useState("");
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");
  const [imagefile, setImageFile] = useState(null); // Track image file
  const [imagePreview1, setImagePreview1] = useState(""); // Track existing image preview URL
  const [imagePreview2, setImagePreview2] = useState(""); // Track new image preview URL
  const [imagechange, setImagechange] = useState(true);
  const BASE_URL = "https://etplbe.etpl.cloud/";

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    if (heading && link && description) {
      let imageBase64 = null;

      // Convert image file to Base64 if selected
      if (imagefile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          imageBase64 = reader.result.split(",")[1]; // Extract Base64 data
        };
        reader.readAsDataURL(imagefile);
      }

      // Prepare the data to send to the backend
      const dataToSend = {
        heading: heading,
        link: link,
        description: description,
        image: imageBase64 || imagePreview1, // Use existing image if no new image is selected
      };

      try {
        // Make API call to update the latest news
        const response = await UpdatelatestAndDeletelatestnews("PATCH", id, dataToSend);
        updateNews(response.data);
        handleClose(false); // Close the form after submitting
      } catch (error) {
        console.error("Error updating news:", error);
      }
    } else {
      setShowmessage(true); // Show error message if fields are empty
    }
  };

  const getdata = async () => {
    const response = await GetlatestAndAddlatestnews("GET", {});
    const data = response.data.find((item) => item.id === id);

    setHeading(data.heading);
    setLink(data.link);
    setDescription(data.description);
    setImagePreview1(data.image); // Set existing image for preview
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file); // Update image file in state
    setImagePreview2(URL.createObjectURL(file)); // Create a preview URL for the selected image
    setImagechange(false);
  };

  const input =
    "bg-pink-200 px-5 py-2 w-[400px] rounded-2xl focus:outline-none focus:border-b-2 focus:border-pink-600 focus:placeholder-pink-400";

  useEffect(() => {
    getdata(); // Fetch data when component loads or id changes
  }, [id]);

  return (
    <div className="absolute top-0 left-0 w-full z-50 h-[100vh]">
      {isvisible && (
        <div className=" bg-black fixed w-full h-[100vh] bg-opacity-60 z-10 flex justify-center items-center">
          <div className="bg-white border-t-4 border-b-4 border-pink-600 w-fit h-fit rounded-2xl p-3">
            <XMarkIcon
              onClick={() => handleClose(false)} // Close the form
              className="text-red-600 h-8 w-8 float-right text-3xl cursor-pointer hover:text-pink-600 hover:border-pink-600 border-2 border-red-600 rounded-full"
            />
            <form
              onSubmit={handleSubmit}
              className="text-pink-700 text-md grid-cols-1 grid space-y-1"
              encType="multipart/form-data"
            >
              <h1 className="text-center font-bold text-2xl mb-3">
                UPDATE LATEST NEWS
              </h1>
              <label className="text-xl">Heading:</label>
              <input
                name="Heading"
                onChange={(e) => setHeading(e.target.value)}
                value={heading}
                className={input}
                type="text"
              />
              <label className="text-xl">Link:</label>
              <input
                name="link"
                onChange={(e) => setLink(e.target.value)}
                value={link}
                className={input}
                type="text"
              />
              <label className="text-xl">Description:</label>
              <input
                name="description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                className={input}
                type="text"
              />
              <label className="text-xl">Image:</label>
              <div className="flex justify-center">
                {imagechange ? (
                  <img
                    src={`${BASE_URL}${imagePreview1}`}
                    alt="Uploaded Preview"
                    className="bg-pink-200 h-[150px] w-[150px] border-pink-600 border-dashed border-2 rounded-2xl justify-center flex"
                  />
                ) : (
                  <img
                    src={imagePreview2}
                    alt="Upload Preview"
                    className="bg-pink-200 h-[150px] w-[150px] border-pink-600 border-dashed border-2 rounded-2xl justify-center flex"
                  />
                )}
              </div>
              <input name="image" type="file" onChange={handleImageChange} />
              {showmessage && (
                <p className="text-lg text-red-600 mt-2">All the Fields are Required..</p>
              )}
              <span className="flex justify-center">
                <button
                  type="submit"
                  className="border-pink-600 w-[60%] py-2  px-10 border-2 text-xl text-pink-600 rounded-3xl group hover:text-white hover:bg-pink-600"
                >
                  Update
                </button>
              </span>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}