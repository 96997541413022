import SharmilaImage from "../../images/LeadersImage/sharmila.JPG";
import NihaaImage from "../../images/LeadersImage/Partner5.jpg";
import AlagerImage from "../../images/LeadersImage/Alagar.Photo.jpg";
import HariHaranImage from "../../images/LeadersImage/53009.jpg";
import SankarImage from "../../images/LeadersImage/_MG_8270.jpg";
import abuImage from "../../images/LeadersImage/ABBU PHOTO (2).jpeg";

export const partners = [
  {
    id: 1,
    name: "Sharmila",
    position: "Business Unit Head",
    image: SharmilaImage,
    // link: "https://www.linkedin.com/in/sharmilakesavamoorthi",
    link: "#",
  },
  {
    id: 2,
    name: "Nihaa",
    position: "Assistant Manager",
    image: NihaaImage,
    link: "#",
  },
  {
    id: 3,
    name: "Alagar",
    position: "Technical Head",
    image: AlagerImage,
    link: "#",
    // link: "https://www.linkedin.com/in/alagar-r-05b33a252/",
  },
  {
    id: 4,
    name: "Gowri Sankar",
    position: "Back-End Lead",
    image: SankarImage,
    link: "#",
  },
  {
    id: 5,
    name: "Mohamed Abubacker Siddiq",
    position: "UI/UX Lead",
    image: abuImage,
    link: "#",
  },
];