import React, { useState, useEffect } from "react";

const TypingAnimation = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const headings = [
    "Web Development",
    "Mobile App Development",
    "Web Design",
    "E-Commerce",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % headings.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative h-[100px] sm:mt-36 lg:mt-0 overflow-hidden flex flex-col items-center sm:items-start">
      <h1 className="text-2xl font-bold text-pink-600">Looking For</h1>
      <div className="relative h-[50px] w-full flex justify-center sm:justify-start">
        {headings.map((heading, index) => (
          <h2
            key={heading}
            className={`absolute w-full md:w-fit text-yellow-500 text-center transition-opacity duration-500 ease-in-out transform ${
              index === currentIndex
                ? "opacity-100 translate-y-0"
                : "opacity-0 -translate-y-5"
            }`}
          >
            {heading}
          </h2>
        ))}
      </div>
    </div>
  );
};

export default TypingAnimation;
