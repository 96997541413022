import HiringImage from "../../images/newsImage/hiringImage.jpg";
import TelzupImage from "../../images/newsImage/telzupFrontLogo.png";
import ZMMImage from "../../images/newsImage/image.png";

export const newsData = [
  {
    id: 4,
    heading: "ZMM - Zero Middle Man",
    link: "https://zmmfe-lad.etplhub.com/",
    description:
      "ZMM is a no-broker platform that connects buyers directly with manufacturers, eliminating middlemen and reducing costs. Whether you're buying or selling, ZMM ensures a seamless, transparent, and cost-effective transaction. Get the best deals straight from the source with ZMM!",
    image: ZMMImage, // ✅ Removed unnecessary curly braces
    created_at: "2025-02-21",
  },
  {
    id: 5,
    heading: "Telzup",
    link: "https://tzfe.etplhub.com/",
    description:
      "Telzup is a powerful and user-friendly CRM solution designed to streamline sales, automate lead management, and enhance customer engagement. With smart call tracking, WhatsApp automation, and real-time analytics, Telzup helps businesses boost productivity and close deals faster. Optimize your sales process effortlessly with Telzup!",
    image: TelzupImage, // ✅ Corrected image assignment
    created_at: "2025-02-21",
  },
  {
    id: 6,
    heading: "We are Hiring!!",
    link: "https://www.emayamtech.com/Career",
    description:
      "Emayam Tech is looking for passionate developers to join our team! If you have expertise in web development/designing and want to work on exciting projects, apply now. Don't miss this opportunity to grow with us!",
    image: HiringImage, // ✅ Corrected image assignment
    created_at: "2025-02-21",
  },
];
