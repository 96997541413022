import React, { useState, useEffect } from 'react';
import BannerImage from '../../../images/back1.jpg';
import '../../../Pages/Auth/Register/RegisterCss/Register.css';
import { BsArrowBarLeft } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { getUpdateOrDeleteProfile } from '../../../services/EtplApi';

const EditProfile = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        gender: '',
        phone_number: '',
        email: '',
        position: '',
        profile_picture: null,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [userName, setUserName] = useState('');
    const userId = localStorage.getItem("UserId");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await getUpdateOrDeleteProfile(userId, 'get');
                const data = response.data;
                setUserName(data.user.username);
                setFormData({
                    first_name: data.user.first_name,
                    last_name: data.user.last_name,
                    gender: data.gender,
                    phone_number: data.phone_number,
                    email: data.user.email,
                    position: data.position,
                    profile_picture: data.profile_picture ? `data:image/jpeg;base64,${data.profile_picture}` : null,
                });
            } catch (error) {
                setErrorMessage('Error fetching user data. Please try again.');
            }
        };
        fetchUserData();
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                let base64String = reader.result.split(',')[1]; // Extract only Base64 data
                base64String = base64String.replace(/\s/g, ''); // Remove any spaces or new lines
                setFormData({ 
                    ...formData, 
                    profile_picture: base64String // Send only the clean Base64 data
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSuccessMessage('');
        setErrorMessage('');
    
        // Prepare the data to send to the backend
        const dataToSend = {
            user: {
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
            },
            gender: formData.gender,
            position: formData.position,
            phone_number: formData.phone_number,
            profile_picture: formData.profile_picture || null,  // Ensure this is a clean Base64 string
        };
    
        try {
            // Update profile data
            await getUpdateOrDeleteProfile(userId, 'patch', dataToSend);
            setSuccessMessage('Profile updated successfully.');
            navigate('/admin/Admin_Profile_page');
        } catch (error) {
            setErrorMessage('Error updating profile. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='w-full h-[100vh]'>
            <div className='w-full fixed top-0 -z-10'>
                <img src={BannerImage} className='w-full h-[100vh]' alt='' />
            </div>
            <div className='w-full flex items-center justify-evenly h-[80vh] z-10'>
                <form
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                    className='bg-white relative border-t-4 border-pink-500 border-b-4 w-[650px] p-4 rounded-md min-h-[50vh]'
                >
                    {/* Heading */}
                    <h1 className='text-center text-[25px] text-pink-500'>Edit Profile</h1>

                    {/* Error and Success Messages */}
                    <div className='mt-2 text-center'>
                        {errorMessage && <p className='text-red-500'>{errorMessage}</p>}
                        {successMessage && <p className='text-green-500'>{successMessage}</p>}
                    </div>

                    {/* Back Button */}
                    <div className='top-5 left-5 absolute'>
                        <Link to='/admin/Admin_Profile_page'>
                            <BsArrowBarLeft className='text-pink-600 text-[25px]' />
                        </Link>
                    </div>

                    {/* Form Fields */}
                    <div className='mt-4 flex flex-col gap-4'>
                        <div className='flex gap-5'>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label className='text-pink-700 font-bold'>First Name:</label>
                                <input
                                    type='text'
                                    name='first_name'
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    className='bg-pink-200 p-2 rounded-md'
                                />
                            </div>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label className='text-pink-700 font-bold'>Last Name:</label>
                                <input
                                    type='text'
                                    name='last_name'
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    className='bg-pink-200 p-2 rounded-md'
                                />
                            </div>
                        </div>

                        <div className='flex gap-5'>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label className='text-pink-700 font-bold'>Gender:</label>
                                <select
                                    name='gender'
                                    value={formData.gender}
                                    onChange={handleChange}
                                    className='bg-pink-200 p-2 rounded-md'
                                >
                                    <option value=''>Select Gender</option>
                                    <option value='M'>Male</option>
                                    <option value='F'>Female</option>
                                    <option value='O'>Other</option>
                                </select>
                            </div>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label className='text-pink-700 font-bold'>Phone Number:</label>
                                <input
                                    type='text'
                                    name='phone_number'
                                    value={formData.phone_number}
                                    onChange={handleChange}
                                    className='bg-pink-200 p-2 rounded-md'
                                />
                            </div>
                        </div>

                        <div className='flex gap-5'>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label className='text-pink-700 font-bold'>Email:</label>
                                <input
                                    type='email'
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    className='p-2 bg-pink-200 rounded-md'
                                />
                            </div>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label className='text-pink-700 font-bold'>Username:</label>
                                <input
                                    disabled
                                    type='text'
                                    name='username'
                                    value={userName}
                                    onChange={handleChange}
                                    className='bg-gray-400 cursor-not-allowed p-2 rounded-md'
                                />
                            </div>
                        </div>

                        <div className='flex gap-5'>
                            <div className='flex flex-col w-[48%] gap-2'>
                                <label className='text-pink-700 font-bold'>Position:</label>
                                <input
                                    type='text'
                                    name='position'
                                    value={formData.position}
                                    onChange={handleChange}
                                    className='bg-pink-200 p-2 rounded-md'
                                />
                            </div>
                            <div className='flex flex-col relative w-[48%] gap-2'>
                                <label className='text-pink-700 font-bold'>Profile Picture:</label>
                                <input
                                    type='file'
                                    name='profile_picture'
                                    onChange={handleFileChange}
                                    className='bg-pink-200 p-2 rounded-md'
                                />
                                {formData.profile_picture && (
                                    <img src={formData.profile_picture} alt="Current Profile" className='w-14 absolute h-14 left-32 -top-3 rounded-full' />
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className='mt-4 flex items-center justify-center'>
                        <button
                            type='submit'
                            disabled={isSubmitting}
                            className={`bg-red-400 p-2 w-[60%] font-serif font-bold rounded-lg transition-all duration-300 ease-in-out ${
                                isSubmitting ? 'bg-gray-400 text-white' : 'hover:bg-pink-700'
                            }`}
                        >
                            {isSubmitting ? 'Updating...' : 'Update Profile'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditProfile;