import React, { useState, useEffect } from "react";
import "./EditJob.css"; // Add CSS for animations
import { IoClose } from "react-icons/io5";
import { getUpdateOrDeleteJob } from '../../../services/EtplApi'; // Adjust the import path as needed


const EditJob = ({ jobId, onClose }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    qualification: "",
    Key_Skills: "",
    location: "",
    department: "",
    No_Of_Positions: "",
    employment_type: "",
    Work_timing: "",
    Experiance: "",
    responsibility: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await getUpdateOrDeleteJob(jobId, 'get');
        setFormData(response.data); // Assuming the data is in response.data
      } catch (error) {
        console.error("Error fetching job:", error);
      }
    };

    if (jobId) {
      fetchJob();
    }
  }, [jobId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await getUpdateOrDeleteJob(jobId, 'put', formData); // Use 'put' method to update the job
      onClose(); // Close the modal after successful update
    } catch (error) {
      console.error("Error updating job:", error);
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <div className="edit-job-modal">
      <div className="edit-job-content">
        <h2 className="text-2xl font-bold text-pink-600 mb-6">Edit Job</h2>
        <IoClose 
          onClick={onClose}
          className="absolute top-8 right-8 p-1 flex items-center justify-center text-[32px] text-white border-2 border-pink-600 rounded-full hover:bg-pink-600 hover:text-black transition-all"
        />
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Title */}
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-300">
              Job Title
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          {/* Description */}
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-300">
              Description
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows="4"
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          {/* Qualification */}
          <div>
            <label htmlFor="qualification" className="block text-sm font-medium text-gray-300">
              Qualification
            </label>
            <input
              type="text"
              name="qualification"
              value={formData.qualification}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          {/* Key Skills */}
          <div>
            <label htmlFor="Key_Skills" className="block text-sm font-medium text-gray-300">
              Key Skills
            </label>
            <input
              type="text"
              name="Key_Skills"
              value={formData.Key_Skills}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          {/* Location */}
          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-300">
              Location
            </label>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          {/* Department */}
          <div>
            <label htmlFor="department" className="block text-sm font-medium text-gray-300">
              Department
            </label>
            <input
              type="text"
              name="department"
              value={formData.department}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          {/* Number of Positions */}
          <div>
            <label htmlFor="No_Of_Positions" className="block text-sm font-medium text-gray-300">
              Number of Positions
            </label>
            <input
              type="text"
              name="No_Of_Positions"
              value={formData.No_Of_Positions}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          {/* Employment Type */}
          <div>
            <label htmlFor="employment_type" className="block text-sm font-medium text-gray-300">
              Employment Type
            </label>
            <select
              name="employment_type"
              value={formData.employment_type}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            >
              <option value="">Select</option>
              <option value="internship">Internship</option>
              <option value="full-time">Full-Time</option>
              <option value="part-time">Part-Time</option>
            </select>
          </div>

          {/* Work Timing */}
          <div>
            <label htmlFor="Work_timing" className="block text-sm font-medium text-gray-300">
              Work Timing
            </label>
            <select
              name="Work_timing"
              value={formData.Work_timing}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            >
              <option value="">Select</option>
              <option value="On-site">On-site</option>
              <option value="Remote">Remote</option>
              <option value="Hybrid">Hybrid</option>
            </select>
          </div>

          {/* Experience */}
          <div>
            <label htmlFor="Experiance" className="block text-sm font-medium text-gray-300">
              Experience
            </label>
            <input
              type="text"
              name="Experiance"
              value={formData.Experiance}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          {/* Responsibility */}
          <div>
            <label htmlFor="responsibility" className="block text-sm font-medium text-gray-300">
              Responsibility
            </label>
            <textarea
              name="responsibility"
              value={formData.responsibility}
              onChange={handleChange}
              rows="4"
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          {/* Buttons */}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-4 py-2 px-4 border border-pink-600 rounded-md text-sm font-medium text-pink-600 hover:bg-pink-600 hover:text-white"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`py-2 px-4 border border-pink-600 rounded-md text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 ${
                isSubmitting ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isSubmitting ? "Updating..." : "Update Job"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditJob;