import React, { useState } from 'react';
import { LuFileStack } from "react-icons/lu";
import { FaList } from "react-icons/fa";
import { GrContactInfo } from "react-icons/gr";
import { FaRegNewspaper } from "react-icons/fa6";
import { RiTeamFill } from "react-icons/ri";
import SideNavbar from '../../../components/AdminHeader/Side Navbar/SideNavbar';
import { Link } from 'react-router-dom';
import './Dashboard.css'

const AdminDashboard = () => {
   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
   const [buttonvisible, setButtonvisible] = useState(true);
   
   // Functions to handle sidebar opening and closing
   const shownavigation = () => {
       setIsSidebarOpen(true);
       setButtonvisible(false)
   };

   const hidenavigation = () => {
       setIsSidebarOpen(false);
       setButtonvisible(true)
   };

   const value='text-4xl mt-5 cursor-pointer lg:text-4xl'
   return (
       <div className='h-fit'>
           {/* Drawer Component */}
           <div className='flex items-center py-2 px-5'>
                <SideNavbar 
                    shownavigation={shownavigation} 
                    hidenavigation={hidenavigation} 
                    isSidebarOpen={isSidebarOpen}
                    isbuttonvisible={buttonvisible}
           />
           </div>

           {/* Dashboard Content */}
               <div className={`text-pink-600 flex flex-wrap h-[100vh] gap-x-40 gap-y-10 items-center justify-center w-[100%] transition-all duration-500 ${isSidebarOpen ? 'ml-[300px] w-[80%] ' : ''}`}>
                   <Link to='/admin/Admin_Dashboard_Applications' className="hover:scale-105 transition-transform duration-300">
                       <div className="bg-black text-center border-pink-300 hover:border-pink-600 hover:text-purple-700 border-4 p-4 min-h-[165px] w-[450px] lg:w-[500px] rounded-3xl shadow slide-left delay-200">
                           <p><LuFileStack className="h-14 w-14 lg:h-20 lg:w-20 text-purple-700 ml-[180px] hover:text-purple-400 " /></p>
                           <p className={value}>Application Dashboard</p>
                       </div>
                   </Link>
                   <Link to='/admin/Admin_Job_List_view' className="hover:scale-105 transition-transform duration-300">
                       <div className="bg-black text-center border-pink-300 hover:border-pink-600 hover:text-orange-700 border-4 p-4 min-h-[165px] w-[450px] lg:w-[500px] rounded-3xl shadow slide-right delay-400">
                           <p><FaList className="h-14 w-14 lg:h-20 lg:w-20 text-orange-700 ml-[180px] hover:text-orange-400" /></p>
                           <p className={value}>Job Listing</p>
                       </div>
                   </Link>
                   <Link to='/admin/Contact_Form_Dashboard' className="hover:scale-105 transition-transform duration-300">
                       <div className="bg-black text-center border-pink-300 hover:border-pink-600 hover:text-sky-700 border-4 p-4 min-h-[165px] w-[450px] lg:w-[500px] rounded-3xl shadow slide-left delay-600">
                           <p><GrContactInfo className="h-14 w-14 lg:h-20 lg:w-20 text-sky-700 ml-[180px] hover:text-sky-400" /></p>
                           <p className={value}>Contact Forms</p>
                       </div>
                   </Link>
                   <Link to='/admin/partnerCoursel' className="hover:scale-105 transition-transform duration-300">
                       <div className="bg-black text-center border-pink-300 hover:border-pink-600 hover:text-amber-700 border-4 p-4 min-h-[165px] w-[450px] lg:w-[500px] rounded-3xl shadow slide-right delay-200">
                           <p><GrContactInfo className="h-14 w-14 lg:h-20 lg:w-20 text-amber-700 ml-[180px] hover:text-amber-400" /></p>
                           <p className={value}>Partner Caursole</p>
                       </div>
                   </Link>
                   <Link to='/admin/latestNews' className="hover:scale-105 transition-transform duration-300">
                       <div className="bg-black text-center border-pink-300 hover:border-pink-600 hover:text-yellow-700 border-4 p-4 min-h-[165px] w-[450px] lg:w-[500px] rounded-3xl shadow slide-left delay-400">
                           <p><FaRegNewspaper className="h-16 w-16 text-yellow-700 ml-[180px] hover:text-yellow-400" /></p>
                           <p className={value}>Latest News</p>
                       </div>
                   </Link>
                   <Link to='/admin/ourLeadership' className="hover:scale-105 transition-transform duration-300">
                       <div className="bg-black text-center border-pink-300 hover:border-pink-600 hover:text-green-700 border-4 p-4 min-h-[165px] w-[450px] lg:w-[500px] rounded-3xl shadow slide-right delay-600">
                           <p><RiTeamFill className="h-16 w-16 text-green-700 ml-[180px] hover:text-green-400" /></p>
                           <p className={value}>Our Leadership Team</p>
                       </div>
                   </Link>
               </div>
           </div>
   );
};

export default AdminDashboard;