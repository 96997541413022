import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/16/solid";
import { GetPartnerdataAndAddPartnerdata } from "../../../../services/EtplApi";

export default function AddPartnerForm({
  isvisible,
  addpartner,
  handleClose,
}) {
  const [partnername, setHeading] = useState("");
  const [link, setLink] = useState("");
  const [imagefile, setImageFile] = useState(null);
  const [showmessage, setShowmessage] = useState(false);
  const [imagePreview, setImagePreview] = useState(""); // To store image preview URL

  const addPartner = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("Name", partnername);
    formdata.append("link", link);
    if (imagefile) {
      formdata.append("logo", imagefile);
    }
  
    if (partnername || link || imagefile) {
      const response = await GetPartnerdataAndAddPartnerdata('post', formdata);
      addpartner(response.data); // Call the parent function directly
      handleClose(false);
    } else {
      setShowmessage(true);
    }
  };
  

  // Handle image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file); // Store the file in state
    setImagePreview(URL.createObjectURL(file)); // Set the preview URL for the image
  };

   // Input style
   const input ="text-pink-600 font-bold bg-pink-200 px-5 py-2 w-[400px] rounded-2xl focus:outline-none focus:border-b-2 focus:border-pink-600 focus:placeholder-pink-400";

  return (
    <div  className="absolute top-0 left-0 w-full h-[100vh] z-50">
      {isvisible && (
        <div className=" h-full fixed w-full bg-black bg-opacity-60 flex justify-center items-center">
          <div className="bg-white border-t-4 border-b-4 border-pink-600 w-fit h-fit rounded-2xl p-5">
            <XMarkIcon
              onClick={handleClose}
              className="text-red-600 h-8 w-8 float-right text-3xl cursor-pointer hover:text-pink-600 hover:border-pink-600 border-2 border-red-600 rounded-full"
            />
            <form
              className="text-pink-700 text-md grid-cols-1 grid space-y-1"
              encType="multipart/form-data"
            >
              <span>
                <h1 className="text-center font-bold text-2xl">
                  ADD NEW PARTNER
                </h1>
              </span>
              <label className="text-xl">Partner Name:</label>
              <input
                className={input}
                onChange={(e) => setHeading(e.target.value)}
                type="text"
              />
              <label className="text-xl">Link:</label>
              <input
                className={input}
                onChange={(e) => setLink(e.target.value)}
                type="text"
              />
              <label className="text-xl">Partner Logo:</label>
              <div className="flex justify-center">
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Uploaded Preview"
                    className="bg-pink-200 h-[150px] w-[150px] border-pink-600 border-dashed border-2 rounded-2xl justify-center flex"
                  />
                ) : (
                  <p className='text-red-600 text-xl py-10'>Partner Logo not yet uploaded...</p>
                )}
              </div>
              <input
                onChange={handleImageChange} // Handle image file change
                type="file"
              />
              {showmessage && (
                <p className="text-lg text-red-600 mt-2">
                  All the Fields are Required..
                </p>
              )}
              <div className="flex justify-center">
                <button
                  onClick={addPartner}
                  className=" border-pink-600 py-2 w-fit px-10 border-2  text-xl text-pink-600 rounded-3xl group hover:text-white hover:bg-pink-600"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
