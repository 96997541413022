import React, { useState } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { getUpdateOrDeleteProfile } from '../../../services/EtplApi';
import { useNavigate } from 'react-router-dom';

const DeleteProfile = ({ profileId, onClose }) => {
    const [error, setError] = useState('');
    const [isDeleting, setIsDeleting] = useState(false); // State to track deletion process
    const navigate = useNavigate();

    const is_superuser = localStorage.getItem('is_superuser');
    const handleDelete = async () => {
        setIsDeleting(true); // Disable the button and show loading state
        setError(''); // Clear any previous errors

        try {
            await getUpdateOrDeleteProfile(profileId, 'delete');
            onClose();         
                if (is_superuser === 'true') {
                    navigate('/admin/Admin_Profile_Dashboard');  // Navigate to admin page
                } else {
                    navigate('/');  // Navigate to home page
                }            
            window.location.reload();
        } catch (error) {
            setError('Error deleting profile'); // Set error message
        } finally {
            setIsDeleting(false); // Re-enable the button
        }
    };

    return (
        <div className="bg-[rgba(0,0,0,0.5)] fixed z-50 flex items-center justify-center w-full top-0 left-0 h-[100vh]">
            <div className="w-[400px] h-[200px] relative border-2 border-t-pink-500 border-b-pink-500 bg-white rounded-md p-4 flex items-center justify-center flex-col gap-3">
                {error && <h1 className='text-red-600 font-bold py-3 px-6 rounded-lg bg-red-100'>{error}</h1>}
                <h1 className="text-pink-600 font-bold text-[20px] text-center leading-8">
                    Are You Sure Want to Delete This Profile?
                </h1>
                <IoMdCloseCircle
                    className="absolute cursor-pointer transition-all ease-in-out duration-300 hover:text-red-800 text-pink-600 text-[30px] top-3 right-3"
                    onClick={onClose}
                />
                <div className="flex gap-4 w-full">
                    <button
                        onClick={handleDelete}
                        disabled={isDeleting} // Disable the button during deletion
                        className={`w-[50%] flex items-center justify-center rounded-lg text-white font-bold transition-all ease-in-out duration-300 ${
                            isDeleting ? 'bg-gray-500 cursor-not-allowed' : 'bg-red-500 hover:bg-red-700'
                        } p-2`}
                    >
                        {isDeleting ? 'Deleting...' : 'Yes'} 
                    </button>
                    <button
                        onClick={onClose}
                        className="w-[50%] flex items-center justify-center rounded-lg text-white font-bold transition-all ease-in-out duration-300 hover:bg-green-700 bg-green-500 p-2"
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteProfile;