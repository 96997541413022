import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import DeleteProfile from "../../DeleteProfile/DeleteProfile";
import Register from "../../../Auth/Register/Register";
import { getAllProfiles } from "../../../../services/EtplApi";
import SideNavbar from "../../../../components/AdminHeader/Side Navbar/SideNavbar";

const ProfileDashboard = () => {
  const [users, setUsers] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [buttonvisible, setButtonvisible] = useState(true);

  const handleDeleteClick = (profileId) => {
    setSelectedProfileId(profileId);
    setShowDeletePopup(true);
  };

  const handleProfileClick = () => {
    setAddUser(true);
  };

  const handleClosePopup = () => {
    setShowDeletePopup(false);
    setSelectedProfileId(null);
  };

  const handleCloseRegister = () => {
    setAddUser(false);
  };

  useEffect(() => {
    getAllProfiles()
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      });
  }, []);
  // Functions to handle sidebar opening and closing
  const shownavigation = () => {
    setIsSidebarOpen(true);
    setButtonvisible(false);
  };

  const hidenavigation = () => {
    setIsSidebarOpen(false);
    setButtonvisible(true);
  };

  return (
    <div className="p-6 bg-black relative min-h-screen text-white">
      <div className="flex items-center py-2 px-5">
        <SideNavbar
          shownavigation={shownavigation}
          hidenavigation={hidenavigation}
          isSidebarOpen={isSidebarOpen}
          isbuttonvisible={buttonvisible}
        />
      </div>
      {selectedImage && (
        <div
          className="fixed inset-0 z-50 bg-black w-full h-[100vh] top-0 left-0 bg-opacity-70 flex justify-center items-center"
          onClick={() => setSelectedImage(null)}
        >
          <img
            src={selectedImage}
            alt="Profile Large"
            className="w-1/3 h-auto rounded-lg shadow-lg"
            onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking on the image
          />
        </div>
      )}
      <motion.h1
        className="text-3xl text-pink-600 font-bold mb-6 text-center"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Admin Dashboard - Users
      </motion.h1>
      <div className="absolute top-8 right-10">
        <button
          onClick={handleProfileClick}
          className="bg-pink-600 px-3 py-2 hover:bg-pink-800 rounded-md"
        >
          Register New User
        </button>
      </div>
      {addUser && <Register onClose={handleCloseRegister} />}
      {showDeletePopup && (
        <DeleteProfile
          profileId={selectedProfileId}
          onClose={handleClosePopup}
        />
      )}
      <div className="overflow-x-auto">
        <motion.table
          className="min-w-full border border-pink-600 shadow-lg bg-gray-800"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <thead className="bg-gray-700 text-pink-600">
            <tr>
              <th className="py-3 px-6 text-left border-b">ID</th>
              <th className="py-3 px-6 text-left border-b">Username</th>
              <th className="py-3 px-6 text-left border-b">Full Name</th>
              <th className="py-3 px-6 text-left border-b">Email</th>
              <th className="py-3 px-6 text-left border-b">Position</th>
              <th className="py-3 px-6 text-left border-b">Phone</th>
              <th className="py-3 px-6 text-left border-b">Staff Status</th>
              <th className="py-3 px-6 text-left border-b">Profile Picture</th>
              <th className="py-3 px-6 text-left border-b">Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="9" className="text-center py-4">
                  <span className="animate-pulse text-white">Loading...</span>
                </td>
              </tr>
            ) : (
              users.map((user) => (
                <motion.tr
                  key={user.user.id}
                  className="border-b border-gray-700 hover:bg-gray-600 transition"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <td className="py-3 px-6">{user.user.id}</td>
                  <td className="py-3 px-6">{user.user.username}</td>
                  <td className="py-3 px-6">
                    {user.user.first_name} {user.user.last_name}
                  </td>
                  <td className="py-3 px-6">{user.user.email}</td>
                  <td className="py-3 px-6">{user.position}</td>
                  <td className="py-3 px-6">{user.phone_number}</td>
                  <td className="py-3 px-6">
                    {user.user.is_superuser ? "Super User" : "Staff"}
                  </td>
                  <td className="py-3 px-6">
                    <img
                      src={user.profile_picture}
                      alt="Profile"
                      className="w-12 h-12 rounded-full border-2 border-pink-700 cursor-pointer"
                      onClick={() => setSelectedImage(user.profile_picture)}
                    />
                  </td>
                  <td className="py-3 px-6">
                    <button
                      className="bg-pink-600 px-3 py-2 hover:bg-pink-800 rounded-md"
                      onClick={() => handleDeleteClick(user.user.id)}
                    >
                      Delete
                    </button>
                  </td>
                </motion.tr>
              ))
            )}
          </tbody>
        </motion.table>
      </div>
    </div>
  );
};

export default ProfileDashboard;
