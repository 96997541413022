import React, { useEffect, useState } from 'react';
import { GetPartnerdataAndAddPartnerdata } from '../../../services/EtplApi';
import SideNavbar from '../../../components/AdminHeader/Side Navbar/SideNavbar';
import DeletePartnerForm from '../../AdminDashboard/Forms/DeletePartnerForm/DeletePartnerForm';
import UpdatePartnerForm from '../../AdminDashboard/Forms/UpdatePartnerForm/UpdatePartnerForm';
import AddPartnerForm from '../../AdminDashboard/Forms/AddPartnerForm/AddPartnerForm';

export default function PartnerCoursel() {
    const BASE_URL = 'https://etplbe.etpl.cloud';
    const [partnerDatas, setPartnerDatas] = useState([]);
    const [isVisibleAddPartnerForm, setIsVisibleAddPartner] = useState(false);
    const [isVisibleUpdatePartnerForm, setIsVisibleUpdatePartner] = useState(false);
    const [isVisibleDeleteForm, setIsVisibleDeleteForm] = useState(false);
    const [partnerId, setPartnerId] = useState();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [buttonvisible, setButtonvisible] = useState(true);

    const PartnerData = async () => {
        const response = await GetPartnerdataAndAddPartnerdata('GET', {}); // 'GET' method with empty object for no params
        setPartnerDatas(response.data);
    };

    const addpartner = (newData) => {
        setPartnerDatas((prevData) => [...prevData, newData]);
    };

    const updatepartner = (updatedData) => {
        setPartnerDatas((prevData) =>
            prevData.map((item) => (item.id === updatedData.id ? updatedData : item))
        );
    };

    const deletepartner = (id) => {
        setPartnerDatas((prevData) => prevData.filter((item) => item.id !== id));
    };

    const handleVisible = () => setIsVisibleAddPartner(true);
    const isAddhide = () => setIsVisibleAddPartner(false);

    const handleUpdateVisible = (e) => {
        setIsVisibleUpdatePartner(true);
        setPartnerId(parseInt(e.target.id));
    };

    const isUpdatehide = () => setIsVisibleUpdatePartner(false);

    const handleDeleteVisible = (e) => {
        setIsVisibleDeleteForm(true);
        setPartnerId(parseInt(e.target.id));
    };

    const isDeletehide = () => setIsVisibleDeleteForm(false);

    const shownavigation = () => {
        setIsSidebarOpen(true);
        setButtonvisible(false);
    };

    const hidenavigation = () => {
        setIsSidebarOpen(false);
        setButtonvisible(true);
    };

    useEffect(() => {
        PartnerData();
    }, []);

    return (
        <div className="min-h-screen bg-gray-900 text-white">
            <div className="flex items-center justify-between w-full p-4 bg-gray-800 shadow-md">
                <SideNavbar shownavigation={shownavigation} hidenavigation={hidenavigation} isSidebarOpen={isSidebarOpen} isbuttonvisible={buttonvisible} />
                <div className={`text-3xl font-bold text-pink-600 transition-all duration-500 ${isSidebarOpen ? 'ml-48' : ''}`}>
                    PARTNER DETAILS
                </div>
                <button
                    onClick={handleVisible}
                    className="text-xl text-white bg-pink-600 border-2 border-pink-600 rounded-full px-6 py-2 hover:bg-black hover:text-pink-600 transition-all duration-300">
                    Add Partner
                </button>
            </div>

            <div className={`p-4 transition-all duration-500 ${isSidebarOpen ? 'ml-48' : ''}`}>
                <div className="overflow-x-auto bg-gray-800 rounded-lg shadow-md">
                    <table className="min-w-full table-auto">
                        <thead className="bg-pink-600 text-center text-white">
                            <tr>
                                <th className="px-4 py-3">S.NO.</th>
                                <th className="px-4 py-3">NAME</th>
                                <th className="px-4 py-3">LINK</th>
                                <th className="px-4 py-3">LOGO</th>
                                <th className="px-4 py-3">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-300">
                            {partnerDatas.map((item, index) => (
                                <tr key={item.id} className="border-b border-gray-700 hover:bg-gray-700 transition-all duration-200">
                                    <td className="px-4 py-3 text-center">{index + 1}</td>
                                    <td className="px-4 py-3 text-center">{item.Name}</td>
                                    <td className="px-4 py-3 text-center max-w-[200px] break-words">
                                        <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">
                                            {item.link}
                                        </a>
                                    </td>
                                    <td className="px-4 py-3 flex items-center justify-center">
                                        <img
                                            src={`${BASE_URL}${item.logo}`}
                                            alt={item.Name}
                                            className="w-16 h-16 object-cover rounded"
                                        />
                                    </td>
                                    <td className="px-4 py-3 text-center">
                                        <button
                                            id={item.id}
                                            onClick={handleUpdateVisible}
                                            className="mx-2 px-4 py-2 text-sm font-medium text-pink-600 border-2 border-pink-600 rounded-full hover:bg-pink-600 hover:text-white transition-all duration-300">
                                            Update
                                        </button>
                                        <button
                                            id={item.id}
                                            onClick={handleDeleteVisible}
                                            className="mx-2 px-4 py-2 text-sm font-medium text-red-600 border-2 border-red-600 rounded-full hover:bg-red-600 hover:text-white transition-all duration-300">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {isVisibleAddPartnerForm && (
                <AddPartnerForm isvisible={isVisibleAddPartnerForm} handleClose={isAddhide} addpartner={addpartner} />
            )}
            {isVisibleUpdatePartnerForm && (
                <UpdatePartnerForm isvisible={isVisibleUpdatePartnerForm} handleClose={isUpdatehide} id={partnerId} updatepartner={updatepartner} />
            )}
            {isVisibleDeleteForm && (
                <DeletePartnerForm isvisible={isVisibleDeleteForm} id={partnerId} handleClose={isDeletehide} deletepartner={deletepartner} />
            )}
        </div>
    );
}