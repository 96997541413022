import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import "./Dashboard.css";
import CanvasCursor from "../../../components/CustomCursor/CanvasCursor";
import {
  getAllOrSubmitJobApplication,
  deleteJobApplication,
  archiveOrUnarchiveJobApplication,
} from "../../../services/EtplApi";
import SideNavbar from "../../../components/AdminHeader/Side Navbar/SideNavbar";

const BASE_URL = "https://etplbe.etpl.cloud/";

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [buttonvisible, setButtonvisible] = useState(true);
  const [applicants, setApplicants] = useState([]);
  const [archivedApplicants, setArchivedApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    email: "",
    experience: "",
    job_title: "",
  });
  const [view, setView] = useState("active"); // State to track the current view

  useEffect(() => {
    fetchData();
  }, []);

  const clearFilters = () => {
    setFilters({
      email: "",
      experience: "",
      job_title: "",
    });
  };

  const fetchData = async () => {
    try {
      const response = await getAllOrSubmitJobApplication("GET");
      const data = response.data;
      setApplicants(data.filter((applicant) => !applicant.is_archived));
      setArchivedApplicants(data.filter((applicant) => applicant.is_archived));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again later.");
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteJobApplication(id);
      fetchData(); // Refresh data after deletion
    } catch (error) {
      console.error("Error deleting application:", error);
    }
  };

  const handleArchive = async (id) => {
    try {
      await archiveOrUnarchiveJobApplication(id, { is_archived: true });
      fetchData(); // Refresh data after archiving
    } catch (error) {
      console.error("Error archiving application:", error);
    }
  };

  const handleUnarchive = async (id) => {
    try {
      await archiveOrUnarchiveJobApplication(id, { is_archived: false });
      fetchData(); // Refresh data after unarchiving
    } catch (error) {
      console.error("Error unarchiving application:", error);
    }
  };

  const handleDownload = (resumeUrl) => {
    const link = document.createElement("a");
    link.href = `${BASE_URL}/${resumeUrl}`;
    link.download = resumeUrl.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const filteredApplicants = applicants.filter((applicant) => {
    return (
      applicant.email.includes(filters.email) &&
      applicant.experience.includes(filters.experience) &&
      applicant.job_title.includes(filters.job_title)
    );
  });

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: "20px", textAlign: "center" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  // Functions to handle sidebar opening and closing
  const shownavigation = () => {
    setIsSidebarOpen(true);
    setButtonvisible(false);
  };

  const hidenavigation = () => {
    setIsSidebarOpen(false);
    setButtonvisible(true);
  };

  return (
    <div style={{ padding: "20px" }}>
      <div className="flex items-center py-2 px-5">
        <SideNavbar
          shownavigation={shownavigation}
          hidenavigation={hidenavigation}
          isSidebarOpen={isSidebarOpen}
          isbuttonvisible={buttonvisible}
        />
      </div>
      <CanvasCursor />
      <Typography variant="h4" gutterBottom className="text-pink-600">
        Applicant Dashboard
      </Typography>
      {/* Filters */}
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <TextField
          label="Filter by Email"
          name="email"
          value={filters.email}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "white",
              "& fieldset": { borderColor: "white" },
              "&:hover fieldset": { borderColor: "white" },
              "&.Mui-focused fieldset": { borderColor: "pink" },
            },
            "& .MuiInputLabel-root": {
              color: "white",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "pink",
            },
          }}
        />
        <FormControl variant="outlined" size="small" sx={{ minWidth: "150px" }}>
          <InputLabel
            sx={{
              color: "white",
              "&.Mui-focused": { color: "pink" },
            }}
          >
            Experience
          </InputLabel>
          <Select
            name="experience"
            value={filters.experience}
            onChange={handleFilterChange}
            label="Experience"
            sx={{
              color: "white",
              backgroundColor: "black",
              "& .MuiOutlinedInput-notchedOutline": { borderColor: "white" },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "pink",
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: "black",
                  color: "white",
                },
              },
            }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="0-1">0-1 years</MenuItem>
            <MenuItem value="2-3">2-3 years</MenuItem>
            <MenuItem value="4-5">4-5 years</MenuItem>
            <MenuItem value="5-10">5-10 years</MenuItem>
            <MenuItem value="10+">10+ years</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Filter by Job Title"
          name="job_title"
          value={filters.job_title}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "white",
              "& fieldset": { borderColor: "white" },
              "&:hover fieldset": { borderColor: "white" },
              "&.Mui-focused fieldset": { borderColor: "pink" },
            },
            "& .MuiInputLabel-root": {
              color: "white",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "pink",
            },
          }}
        />
        <Button
          variant="contained"
          onClick={clearFilters}
          sx={{
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              backgroundColor: "pink",
            },
          }}
        >
          Clear Filters
        </Button>
      </div>

      {/* Toggle Button for Active/Archived Applicants */}
      <div className="border-gray-400 border-[1px] rounded-md shadow-md shadow-black p-1 w-[400px]">
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="view"
          sx={{
            whiteSpace: "nowrap",
            borderRadius: "8px",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <ToggleButton
            value="active"
            aria-label="active"
            sx={{
              whiteSpace: "nowrap",
              width: "200px",
              fontWeight: "bold",
              borderRadius: "8px",
              transition: "all 0.3s ease",
              backgroundColor: view === "active" ? "pink" : "black",
              color: view === "active" ? "black" : "white",
              boxShadow:
                view === "active" ? "0px 4px 6px rgba(0,0,0,0.2)" : "none",
              "&.Mui-selected": {
                backgroundColor: "pink",
                color: "black",
              },
              "&:hover": {
                backgroundColor: view !== "active" ? "pink" : "pink",
                color: view !== "active" ? "black" : "black",
              },
            }}
          >
            Active Applicants
          </ToggleButton>
          <ToggleButton
            value="archived"
            aria-label="archived"
            sx={{
              width: "200px",
              fontWeight: "bold",
              borderRadius: "8px",
              transition: "all 0.3s ease",
              backgroundColor: view === "archived" ? "pink" : "black",
              color: view === "archived" ? "black" : "white",
              boxShadow:
                view === "archived" ? "0px 4px 6px rgba(0,0,0,0.2)" : "none",
              "&.Mui-selected": {
                backgroundColor: "pink",
                color: "black",
              },
              "&:hover": {
                backgroundColor: view !== "archived" ? "pink" : "pink",
                color: view !== "archived" ? "black" : "black",
              },
            }}
          >
            Archived Applicants
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      {/* Active Applicants Table */}
      {view === "active" && (
        <>
          <Typography variant="h6" gutterBottom>
            Active Applicants
          </Typography>
          {filteredApplicants.length === 0 ? (
            <Typography
              variant="h6"
              style={{ textAlign: "center", marginTop: "20px", color: "gray" }}
            >
              No active applications available.
            </Typography>
          ) : (
            <TableContainer
              component={Paper}
              style={{ marginBottom: "40px", overflowX: "auto" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: "150px" }}>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Experience</TableCell>
                    <TableCell>Job Title</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell style={{ minWidth: "200px" }}>Subject</TableCell>
                    <TableCell>Resume</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredApplicants.map((applicant) => (
                    <TableRow key={applicant.id}>
                      <TableCell style={{ minWidth: "150px" }}>
                        {applicant.name}
                      </TableCell>
                      <TableCell>{applicant.email}</TableCell>
                      <TableCell>{applicant.mobile_number}</TableCell>
                      <TableCell>{applicant.experience}</TableCell>
                      <TableCell>{applicant.job_title}</TableCell>
                      <TableCell>{applicant.current_location}</TableCell>
                      <TableCell
                        style={{
                          minWidth: "200px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        {applicant.subject}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<DownloadIcon />}
                          onClick={() => handleDownload(applicant.resume)}
                          className="animated-button"
                        >
                          Download
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<DeleteIcon />}
                          onClick={() => handleDelete(applicant.id)}
                          style={{ marginRight: "10px" }}
                          className="animated-button w-[120px]"
                        >
                          Delete
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<ArchiveIcon />}
                          onClick={() => handleArchive(applicant.id)}
                          className="animated-button mt-2 w-[120px]"
                        >
                          Archive
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}

      {/* Archived Applicants Table */}
      {view === "archived" && (
        <>
          <Typography variant="h6" gutterBottom>
            Archived Applicants
          </Typography>
          {archivedApplicants.length === 0 ? (
            <Typography
              variant="h6"
              style={{ textAlign: "center", marginTop: "20px", color: "gray" }}
            >
              No archived applications available.
            </Typography>
          ) : (
            <TableContainer component={Paper} style={{ overflowX: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: "150px" }}>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Experience</TableCell>
                    <TableCell>Job Title</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell style={{ minWidth: "200px" }}>Subject</TableCell>
                    <TableCell>Resume</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {archivedApplicants.map((applicant) => (
                    <TableRow key={applicant.id}>
                      <TableCell style={{ minWidth: "150px" }}>
                        {applicant.name}
                      </TableCell>
                      <TableCell>{applicant.email}</TableCell>
                      <TableCell>{applicant.mobile_number}</TableCell>
                      <TableCell>{applicant.experience}</TableCell>
                      <TableCell>{applicant.job_title}</TableCell>
                      <TableCell>{applicant.current_location}</TableCell>
                      <TableCell
                        style={{
                          minWidth: "200px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        {applicant.subject}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<DownloadIcon />}
                          onClick={() => handleDownload(applicant.resume)}
                          className="animated-button"
                        >
                          Download
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<UnarchiveIcon />}
                          onClick={() => handleUnarchive(applicant.id)}
                          className="animated-button"
                        >
                          Unarchive
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
