import React, { useEffect, useState } from 'react';
import AddLatestNewsForm from '../../../Pages/AdminDashboard/Forms/AddLatestNewsForm/AddLatestNewsForm';
import UpdateLatestNewsForm from '../../../Pages/AdminDashboard/Forms/UpdateLatestNewsForm/UpdateLatestNewsForm';
import { GetlatestAndAddlatestnews } from '../../../services/EtplApi';
import DeleteLatestNewsForm from '../../../Pages/AdminDashboard/Forms/DeleteForm/DeleteLatestNewsForm';
import SideNavbar from '../../../components/AdminHeader/Side Navbar/SideNavbar';

export default function LatestNews() {
    const [latestnewsdatas, setLatestNewsDatas] = useState([]);
    const [isVisibleAddLatestNewsForm, setIsVisibleAddLatestNews] = useState(false);
    const [isVisibleUpdateLatestNewsForm, setIsVisibleUpdateLatestNews] = useState(false);
    const [isVisibleDeleteForm, setIsVisibleDeleteForm] = useState(false);
    const [latestNewsId, setLatestNewsId] = useState();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [buttonvisible, setButtonvisible] = useState(true);
    const [isLoading, setIsLoading] = useState(true); // Add loading state

    const LatestNewsData = async () => {
        try {
            const response = await GetlatestAndAddlatestnews('GET', {}); // 'GET' method with empty object for no params
            setLatestNewsDatas(response.data);
        } catch (error) {
            console.error('Error fetching latest news data:', error);
        } finally {
            setIsLoading(false); // Set loading to false after fetching data
        }
    };

    const addNews = (newData) => {
        setLatestNewsDatas((prevData) => [...prevData, newData]);
    };

    const updateNews = (updatedData) => {
        setLatestNewsDatas((prevData) =>
            prevData.map((item) => (item.id === updatedData.id ? updatedData : item))
        );
    };

    const deleteNews = (id) => {
        setLatestNewsDatas((prevData) => prevData.filter((item) => item.id !== id));
    };

    const handleVisible = () => setIsVisibleAddLatestNews(true);
    const isAddhide = () => setIsVisibleAddLatestNews(false);

    const handleUpdateVisible = (e) => {
        setIsVisibleUpdateLatestNews(true);
        setLatestNewsId(parseInt(e.target.id));
    };

    const isUpdatehide = () => setIsVisibleUpdateLatestNews(false);

    const handleDeleteVisible = (e) => {
        setIsVisibleDeleteForm(true);
        setLatestNewsId(parseInt(e.target.id));
    };

    const isDeletehide = () => setIsVisibleDeleteForm(false);

    const shownavigation = () => {
        setIsSidebarOpen(true);
        setButtonvisible(false);
    };

    const hidenavigation = () => {
        setIsSidebarOpen(false);
        setButtonvisible(true);
    };

    useEffect(() => {
        LatestNewsData();
    }, []);

    if (isLoading) {
        return <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">Loading...</div>;
    }

    return (
        <div className="min-h-screen bg-gray-900 text-white">
            <div className="flex items-center justify-between w-full p-4 bg-gray-800 shadow-md">
                <SideNavbar shownavigation={shownavigation} hidenavigation={hidenavigation} isSidebarOpen={isSidebarOpen} isbuttonvisible={buttonvisible} />
                <div className={`text-3xl font-bold text-pink-600 transition-all duration-500 ${isSidebarOpen ? 'ml-48' : ''}`}>
                    LATEST NEWS
                </div>
                <button
                    onClick={handleVisible}
                    className="text-xl text-white bg-pink-600 border-2 border-pink-600 rounded-full px-6 py-2 hover:bg-black hover:text-pink-600 transition-all duration-300">
                    Add Latest News
                </button>
            </div>

            <div className={`p-4 transition-all duration-500 ${isSidebarOpen ? 'ml-48' : ''}`}>
                <div className="overflow-x-auto bg-gray-800 rounded-lg shadow-md">
                    <table className="min-w-full table-auto">
                        <thead className="bg-pink-600 text-white">
                            <tr>
                                <th className="px-4 py-3">S.NO.</th>
                                <th className="px-4 py-3">HEADING</th>
                                <th className="px-4 py-3">LINK</th>
                                <th className="px-4 py-3">DESCRIPTION</th>
                                <th className="px-4 py-3">IMAGE</th>
                                <th className="px-4 py-3">CREATED AT</th>
                                <th className="px-4 py-3">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-300">
                            {latestnewsdatas.map((item, index) => (
                                <tr key={item.id} className="border-b border-gray-700 hover:bg-gray-700 transition-all duration-200">
                                    <td className="px-4 py-3 text-center">{index + 1}</td>
                                    <td className="px-4 py-3 text-center">{item.heading}</td>
                                    <td className="px-4 py-3 text-center max-w-[200px] break-words">
                                        <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">
                                            {item.link}
                                        </a>
                                    </td>
                                    <td className="px-4 py-3 max-w-[300px] break-words relative group">
                                        <div className="truncate transition-all duration-500 group-hover:whitespace-normal">
                                            {item.description}
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 flex items-center justify-center">
                                        {/* Display the image using Base64 data */}
                                        {item.image ? (
                                            <img
                                                src={item.image} // Prepend the Base64 prefix
                                                alt={item.heading}
                                                className="w-16 h-16 object-cover rounded"
                                            />
                                        ) : (
                                            <div className="w-16 h-16 bg-gray-700 flex items-center justify-center rounded">
                                                <p className="text-xs text-gray-400">No Image</p>
                                            </div>
                                        )}
                                    </td>
                                    <td className="px-4 py-3 text-center">{item.created_at}</td>
                                    <td className="px-4 py-3 text-center">
                                        <button
                                            id={item.id}
                                            onClick={handleUpdateVisible}
                                            className="mx-2 px-4 py-2 text-sm font-medium text-pink-600 border-2 border-pink-600 rounded-full hover:bg-pink-600 hover:text-white transition-all duration-300">
                                            Update
                                        </button>
                                        <button
                                            id={item.id}
                                            onClick={handleDeleteVisible}
                                            className="mx-2 px-4 py-2 text-sm font-medium text-red-600 border-2 border-red-600 rounded-full hover:bg-red-600 hover:text-white transition-all duration-300">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {isVisibleAddLatestNewsForm && (
                <AddLatestNewsForm isvisible={isVisibleAddLatestNewsForm} handleClose={isAddhide} addNews={addNews} />
            )}
            {isVisibleUpdateLatestNewsForm && (
                <UpdateLatestNewsForm isvisible={isVisibleUpdateLatestNewsForm} handleClose={isUpdatehide} id={latestNewsId} updateNews={updateNews} />
            )}
            {isVisibleDeleteForm && (
                <DeleteLatestNewsForm isvisible={isVisibleDeleteForm} id={latestNewsId} handleClose={isDeletehide} deleteNews={deleteNews} />
            )}
        </div>
    );
}