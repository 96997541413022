import { useEffect, useState } from "react";
import logo from "../../images/Logo.png";
import { FaArrowUp, FaBars, FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showArrow, setShowArrow] = useState(false); // State to track arrow visibility
  const location = useLocation(); // Get the current location

  const handleMenuToggle = () => {
    setShowBackdrop(true); // Show the backdrop immediately
    setTimeout(() => {
      setIsOpen(!isOpen); // Then open the menu after a delay
    }, 300); // Delay for the backdrop to show before menu opens
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
    setShowBackdrop(false); // Hide the backdrop
  };

  const handleBackdropClick = () => {
    setIsOpen(false); // Close the menu
    setTimeout(() => {
      setShowBackdrop(false); // Hide the backdrop after a delay
    }, 300); // Match the duration of the menu transition
  };

  // State for bottom border animation
  const [borderWidth, setBorderWidth] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollPosition = window.scrollY;
      const progress = (scrollPosition / scrollHeight) * 100; // Convert to percentage
      setBorderWidth(progress);

      // Show arrow only if the user scrolls down
      setShowArrow(scrollPosition > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to top when the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="w-full h-[15vh] z-50">
      <div className="fixed top-0 w-full bg-[rgba(0,0,0,0.9)] h-[15vh] flex items-center justify-evenly px-6 z-50">
        {/* Logo Section */}
        <div className="flex items-center justify-center md:w-[20%]">
          <Link to="/"><img src={logo} alt="Logo" className="h-14 w-48" /></Link>
        </div>

        {/* Mobile Menu Icon */}
        <button
          className="text-white text-3xl absolute right-10 md:hidden"
          onClick={handleMenuToggle}
        >
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* Navigation and Contact Button */}
        <div className="flex w-[85%] items-center justify-evenly">
          <nav
            className={`fixed md:static md:border-none border-t-2 border-pink-400 rounded-tr-md top-[15vh] left-0 w-[250px] h-[85vh] md:h-auto md:flex flex md:items-center justify-center transition-transform duration-500 ${
              isOpen ? "translate-x-0" : "-translate-x-full"
            } md:translate-x-0 md:w-auto bg-black md:bg-transparent z-50`}
          >
            <ul className="flex flex-col items-center md:flex-row md:gap-6 text-white font-bold text-lg md:text-sm">
              {[
                { name: "Home", path: "/" },
                { name: "Services", path: "/services" },
                { name: "About Us", path: "/about" },
                { name: "Career", path: "/Career" },
              ].map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.path}
                    onClick={handleLinkClick} // Close the menu on link click
                    className={`transition-all p-3 md:p-0 mt-3 md:mt-0 whitespace-nowrap ease-in-out duration-300 block px-4 py-2 hover:text-pink-500 ${
                      location.pathname === item.path ? "text-pink-500" : ""
                    }`}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}

              {/* Contact Button inside Mobile Nav */}
              <li className="md:hidden mt-4">
                <Link
                  to="/contact"
                  onClick={handleLinkClick} // Close the menu on link click
                  className={`border-2 hover:bg-pink-500 hover:border-white transition-all ease-out duration-300 border-pink-500 px-4 py-2 rounded-xl text-[20px] ${
                    location.pathname === "/contact"
                      ? "bg-pink-500 border-white"
                      : ""
                  }`}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>

          {/* Contact Button for Desktop */}
          <div className="hidden md:block mt-2">
            <Link
              to="/contact"
              onClick={handleLinkClick} // Close the menu on link click
              className={`border-2 hover:bg-pink-500 text-white transition-all ease-out duration-300 border-pink-500 px-4 py-2 rounded-[30px] text-[20px] ${
                location.pathname === "/contact"
                  ? "bg-pink-500 border-white"
                  : ""
              }`}
            >
              Contact
            </Link>
          </div>
        </div>
      </div>

      {/* Border animation at the bottom of the navbar */}
      <div
        className="fixed top-[15vh] left-0 h-[4px] z-50 bg-green-600 transition-all duration-300"
        style={{ width: `${borderWidth}%` }}
      />

      {/* Backdrop for mobile menu with fade-in effect */}
      {showBackdrop && (
        <div
          onClick={handleBackdropClick} // Close the menu when clicking the backdrop
          className={`fixed inset-0 bg-pink-300 bg-opacity-50 z-40 transition-opacity duration-300 ${
            isOpen ? "opacity-100" : "opacity-0"
          }`}
        />
      )}

      {/* Up Arrow Button (Show only when scrolled down) */}
      {showArrow && (
        <div className="fixed fa-bounce bg-black z-50 hover:border-white hover:bg-pink-500 bottom-10 right-10 w-12 h-12 flex items-center justify-center text-2xl rounded-full shadow-lg border-2 border-pink-500 cursor-pointer">
          <a href="#top" className="p-3">
            <FaArrowUp className="text-white"/>
          </a>
        </div>
      )}
    </div>
  );
};

export default Navbar;