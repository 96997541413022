import React, { useState, useEffect, useRef } from 'react';
import { getAllOrPostJob } from '../../../services/EtplApi'; // Adjust the import path as needed
import { Link, useNavigate } from 'react-router-dom'; // For redirection
import "./PostJob.css"
import { IoClose } from "react-icons/io5";

const PostJob = () => {
  const navigate = useNavigate(); // Hook for navigation
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    qualification: '',
    Key_Skills: '',
    location: '',
    department: '',
    No_Of_Positions: '',
    employment_type: '',
    Work_timing: '',
    Experiance: '',
    description: '',
    responsibility: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Button state
  const [successMessage, setSuccessMessage] = useState(''); // Success message
  const [scrollProgress, setScrollProgress] = useState(0); // Scroll progress state

  const formRef = useRef(null); // Ref for the form container

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e, action) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable buttons

    try {
      const response = await getAllOrPostJob('post', formData);
      console.log('Job posted successfully:', response.data);

      setSuccessMessage('Job posted successfully!');

      if (action === 'saveAndClose') {
        setTimeout(() => {
          navigate('/admin/Admin_Job_List_view'); // Redirect to career page
        }, 1500);
      } else if (action === 'saveAndAddAnother') {
        // Reset form for adding another post
        setFormData({
          title: '',
          description: '',
          qualification: '',
          Key_Skills: '',
          location: '',
          department: '',
          No_Of_Positions: '',
          employment_type: '',
          Work_timing: '',
          Experiance: '',
          description: '',
          responsibility: ''
        });
        setIsSubmitting(false); // Re-enable buttons
      }
    } catch (error) {
      console.error('Error posting job:', error);
      alert('Error posting job. Please try again.');
      setIsSubmitting(false); // Re-enable buttons on error
    }
  };

  useEffect(() => {
    const formElement = formRef.current;

    const handleScroll = () => {
      const scrollTop = formElement.scrollTop;
      const scrollHeight = formElement.scrollHeight - formElement.clientHeight;
      const progress = (scrollTop / scrollHeight) * 100;
      setScrollProgress(progress);
    };

    formElement.addEventListener('scroll', handleScroll);

    return () => {
      formElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="min-h-[70vh] bg-black flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="h-[70vh] overflow-y-scroll relative no-scrollbar max-w-2xl border-2 border-pink-600 w-full bg-black text-white rounded-lg px-8 pb-8" ref={formRef}>
        <h2 className="text-3xl  sticky top-0 py-4 font-bold text-center text-pink-600 mb-8 bg-black">
          Post a Job
            <div className="w-full bg-white mt-3 h-1">
                <div className="bg-red-700 h-1" style={{ width: `${scrollProgress}%` }}></div>
            </div>
            <Link to="/admin/Admin_Job_List_view">
              <IoClose  className='text-pink-600 border-2 transition-all duration-300 ease-in-out hover:bg-pink-400 border-pink-600 absolute top-6 right-4 rounded-full p-1'/>
            </Link>
        </h2>
        
        {successMessage && (
          <div className="mb-6 p-2 text-center bg-green-500 text-white rounded-md">
            {successMessage}
          </div>
        )}
        <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
          {/* Two inputs in a single row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-300">
                Job Title
              </label>
              <input
                type="text"
                name="title"
                id="title"
                value={formData.title}
                onChange={handleChange}
                className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
                required
              />
            </div>
            <div>
              <label htmlFor="location" className="block text-sm font-medium text-gray-300">
                Location
              </label>
              <input
                type="text"
                name="location"
                id="location"
                value={formData.location}
                onChange={handleChange}
                className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
                required
              />
            </div>
          </div>

          {/* Two inputs in a single row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="department" className="block text-sm font-medium text-gray-300">
                Department
              </label>
              <input
                type="text"
                name="department"
                id="department"
                value={formData.department}
                onChange={handleChange}
                className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
                required
              />
            </div>
            <div>
              <label htmlFor="No_Of_Positions" className="block text-sm font-medium text-gray-300">
                Number of Positions
              </label>
              <input
                type="text"
                name="No_Of_Positions"
                id="No_Of_Positions"
                value={formData.No_Of_Positions}
                onChange={handleChange}
                className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
                required
              />
            </div>
          </div>

          {/* Two inputs in a single row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="employment_type" className="block text-sm font-medium text-gray-300">
                Employment Type
              </label>
              <select
                name="employment_type"
                id="employment_type"
                value={formData.employment_type}
                onChange={handleChange}
                className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
                required
              >
                <option value="">Select</option>
                <option value="internship">Internship</option>
                <option value="full-time">Full-Time</option>
                <option value="part-time">Part-Time</option>
              </select>
            </div>
            <div>
              <label htmlFor="Work_timing" className="block text-sm font-medium text-gray-300">
                Work Mood
              </label>
              <select
                name="Work_timing"
                id="Work_timing"
                value={formData.Work_timing}
                onChange={handleChange}
                className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
                required
              >
                <option value="">Select</option>
                <option value="On-site">On-site</option>
                <option value="Remote">Remote</option>
                <option value="Hybrid">Hybrid</option>
              </select>
            </div>
          </div>

          {/* Single row fields */}
          <div>
            <label htmlFor="qualification" className="block text-sm font-medium text-gray-300">
              Qualification
            </label>
            <input
              type="text"
              name="qualification"
              id="qualification"
              value={formData.qualification}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          <div>
            <label htmlFor="Key_Skills" className="block text-sm font-medium text-gray-300">
              Key Skills
            </label>
            <input
              type="text"
              name="Key_Skills"
              id="Key_Skills"
              value={formData.Key_Skills}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          <div>
            <label htmlFor="Experiance" className="block text-sm font-medium text-gray-300">
              Experience
            </label>
            <input
              type="text"
              name="Experiance"
              id="Experiance"
              value={formData.Experiance}
              onChange={handleChange}
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
              required
            />
          </div>

          <div>
            <label htmlFor="responsibility" className="block text-sm font-medium text-gray-300">
              Responsibility
            </label>
            <textarea
              required
              name="responsibility"
              id="responsibility"
              value={formData.responsibility}
              onChange={handleChange}
              rows="4"
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
            />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-300">
                description
            </label>
            <textarea
              required
              name="description"
              id="description"
              value={formData.description}
              onChange={handleChange}
              rows="4"
              className="mt-1 block w-full bg-black border-b-2 border-pink-600 focus:outline-none focus:border-pink-600 text-white"
            />
          </div>

          {/* Buttons */}
          <div className="flex justify-between">
            <button
              type="button"
              onClick={(e) => handleSubmit(e, 'saveAndClose')}
              disabled={isSubmitting}
              className={`py-2 px-4 border border-pink-600 rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none ${
                isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              Save and Close
            </button>
            <button
              type="button"
              onClick={(e) => handleSubmit(e, 'saveAndAddAnother')}
              disabled={isSubmitting}
              className={`py-2 px-4 border border-pink-600 rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none ${
                isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              Save and Add Another
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostJob;