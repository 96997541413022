import React, { useEffect, useState } from "react";
import LogoImage from "../../../images/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { verifyOTP, ResendOTP } from "../../../services/EtplApi"; // Import the APIs

const OTPVerification = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // State for 6-digit OTP
  const navigate = useNavigate();

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setInterval(() => {
        setCounter((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [counter]);

  // Handle OTP input change
  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Auto-focus to the next input
    if (value && index < 5) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const email = localStorage.getItem("email");
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");

    const otpCode = otp.join(""); // Combine the OTP digits into a single string

    try {
      const response = await verifyOTP({ otp: otpCode, email: email });
      if (response.data) {
        setSuccessMessage("OTP verified successfully! Redirecting...");
        setTimeout(() => {
          navigate("/admin/dashboard"); // Redirect to the dashboard or desired page
        }, 2000);
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          "OTP verification failed. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle resend OTP
  const handleResendOTP = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await ResendOTP({ email: email });
      if (response.data) {
        setSuccessMessage("OTP resent successfully!");
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          "Failed to resend OTP. Please try again."
      );
    }
  };

  return (
    <div className="bg-black h-[100vh] w-full flex items-center justify-center flex-col gap-10">
      <div>
        <img src={LogoImage} alt="Logo" className="w-[280px] md:w-[320px]" />
      </div>
      <div>
        <form
          onSubmit={handleSubmit}
          className="bg-white border-t-4 border-t-red-500 border-b-4 border-b-red-600 p-3 rounded-md w-[100%] sm:w-[450px] min-h-[35vh]"
        >
          {/* Display success or error messages */}
          {successMessage && (
            <div className="bg-green-100 border text-center border-green-400 text-green-700 px-4 py-3 rounded mb-4">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="bg-red-100 border text-center border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {errorMessage}
            </div>
          )}

          <h1 className="text-pink-600 text-[23px] mt-1 text-center tracking-wider font-bold font-serif">
            OTP Verification
          </h1>
          <div className="flex justify-center gap-2 mt-4">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-${index}`}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                className="w-14 h-14 bg-pink-300 text-center rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                required
              />
            ))}
          </div>
          <div className="mt-6 flex items-center justify-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`bg-red-400 w-[60%] py-2 font-serif font-bold rounded-lg transition-all duration-300 ease-in-out ${
                isSubmitting
                  ? "bg-gray-400 text-white cursor-not-allowed"
                  : "hover:bg-pink-700"
              }`}
            >
              {isSubmitting ? "Verifying..." : "Verify OTP"}
            </button>
          </div>
          <div className="mt-4 text-center">
            <p className="text-black font-serif">
              Didn't receive the OTP?{" "}
              <button
                type="button"
                onClick={() => {
                  handleResendOTP();
                  setCounter(60); // Start countdown
                }}
                disabled={counter > 0}
                className={`underline transition-all duration-300 ease-in-out ${
                  counter > 0
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-400 hover:text-blue-800"
                }`}
              >
                {counter > 0 ? `Resend OTP in ${counter}s` : "Resend OTP"}
              </button>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OTPVerification;
