import React, { useState } from "react";
import LogoImage from "../../../images/Logo.png";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { changePassword } from "../../../services/EtplApi";

const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false); // State for old password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // State for new password visibility
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const access_token = localStorage.getItem("access_token");
      if (!access_token) {
        throw new Error("No access token found");
      }

      const response = await changePassword(formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (response.status === 200) {
        setSuccessMessage("Password changed successfully!");
        setTimeout(() => {
          navigate("/admin/DashBoard");
        }, 2000); // Redirect after 2 seconds
      } else {
        throw new Error("Failed to change password");
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "An error occurred while changing the password.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-black h-[100vh] font-serif w-full flex items-center justify-center flex-col gap-10">
      <div>
        <img src={LogoImage} alt="Logo" className="w-[280px] md:w-[320px]" />
      </div>
      <div>
        <form
          onSubmit={handleSubmit}
          className="bg-white border-t-4 border-t-red-500 border-b-4 border-b-red-600 p-3 rounded-md w-[300px] sm:w-[350px] md:w-[450px] "
        >
          {/* Display success or error messages */}
          {successMessage && (
            <div className="bg-green-100 border text-center border-green-400 text-green-700 px-4 py-3 rounded mb-4">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="bg-red-100 border text-center border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {errorMessage}
            </div>
          )}

          <h1 className="text-pink-600 text-[23px] mt-1 text-center tracking-wider font-bold font-serif">
            Change Password
          </h1>

          {/* Old Password Field */}
          <div className="relative mt-3 mb-4">
            <label htmlFor="old_password" className="text-pink-800">Old Password:</label>
            <input
              type={showOldPassword ? "text" : "password"}
              name="old_password"
              value={formData.old_password}
              onChange={handleInputChange}
              placeholder="Enter Your Old Password"
              className="w-full p-2 border rounded-md bg-pink-200"
            />
            <button
              type="button"
              onClick={() => setShowOldPassword(!showOldPassword)}
              className="absolute right-3 top-14 transform -translate-y-1/2 focus:outline-none"
            >
              {showOldPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>

          {/* New Password Field */}
          <div className="relative mb-4">
            <label htmlFor="new_password" className="text-pink-800">New Password:</label>
            <input
              type={showNewPassword ? "text" : "password"}
              name="new_password"
              value={formData.new_password}
              onChange={handleInputChange}
              placeholder="Enter Your New Password"
              className="w-full p-2 border rounded-md bg-pink-200"
            />
            <button
              type="button"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className="absolute right-3 top-14 transform -translate-y-1/2 focus:outline-none"
            >
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>

          <div className="mt-6 flex items-center justify-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`bg-red-400 px-5 py-2 font-serif font-bold rounded-lg transition-all duration-300 ease-in-out ${
                isSubmitting ? "bg-gray-400 text-white cursor-not-allowed" : "hover:bg-pink-700"
              }`}
            >
              {isSubmitting ? "Changing..." : "Change Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;