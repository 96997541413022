import "./App.css";
import Navbar from "./components/Header/Navbar";
import Footer from "./components/Footer/Footer";
import { Outlet } from "react-router-dom"; // Needed to render child routes

function App() {
  return (
    <>
      <Navbar />
        <Outlet />
      <Footer />
    </>
  );
}

export default App;



// import React from "react";
// import ImageUpload from "./Pages/sample/ImageUpload";
// import ImageGallery from "./Pages/sample/ImageGallery";


// function App() {
//     return (
//         <div className="bg-white">
//             <h1>Image Storage System</h1>
//             <ImageUpload />
//             <ImageGallery />
//         </div>
//     );
// }

// export default App;
