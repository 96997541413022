import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../Pages/HomePage/Home';
import About from '../Pages/AboutUs Page/About';
import Services from '../Pages/ServicePage/Services';
import Resources from '../Pages/ResourcesPage/Resources';
import Contact from '../Pages/ContactPage/Contact';
import CareerPage from '../Pages/CareerPage/CareerPage';
import Dashboard from '../Pages/CareerPage/Dashboard/Dashboard';
import PostJob from '../Pages/CareerPage/forms/PostJob';
import JobList from '../Pages/CareerPage/Dashboard/JobList';
import Login from '../Pages/Auth/Login/Login';
import Register from '../Pages/Auth/Register/Register';
import ForgotPassword from '../Pages/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from '../Pages/Auth/ResetPassword/ResetPassword';
import ChangePassword from '../Pages/Auth/ChangePassword/ChangePassword';
import OTPVerification from '../Pages/Auth/OTPVerification/OTPVerification';
import Profile from '../Pages/ProfilePage/Profile';
import EditProfile from '../Pages/ProfilePage/EditProfile/EditProfile';
import PrivateRouter from './PrivateRouter/PrivateRouter';
import ProfileDashboard from '../Pages/ProfilePage/ProfilesDashboard/ProfilesDashboard/ProfileDashboard';
import ContactFormDashboard from '../Pages/AdminDashboard/ContactForm/ContactFormDashboard';
import AdminDashboard from '../Pages/AdminDashboard/Dashboard/AdminDashboard';
import LatestNews from '../Pages/AdminDashboard/Latest News/LatestNews';
import Layout from './Layout/Layout'
import PartnerCoursel from '../Pages/AdminDashboard/Partner Coursel/PartnerCoursel';
import OurLeadershipTeam from '../Pages/AdminDashboard/OurLeadershipTeam/OurLeadershipTeam';

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            { path: '/', element: <Home /> },
            { path: 'about', element: <About /> },
            { path: 'services', element: <Services /> },
            { path: 'resources', element: <Resources /> },
            { path: 'contact', element: <Contact /> },
            { path: 'Career', element: <CareerPage /> }
        ]
    },
    { path: 'ETPL_Admin_Login', element: <Login /> },
    { path: 'ETPL_Admin_register', element: <Register /> },
    { path: 'ETPL_OTP_Confirmation', element: <OTPVerification /> },
    { path: 'ETPL_Forgot_Password', element: <ForgotPassword /> },
    { path: 'reset-password/:token', element: <ResetPassword /> },  // Use route parameter
    { path: 'ETPL_Change_Password', element: <ChangePassword /> },
    {
        path: '/admin',
        element: <Layout />,  // Main layout with Header/Footer
        children: [
          {
            path: 'DashBoard',  // Corrected path
            element: <PrivateRouter element={<AdminDashboard />} />
          },
          {
            path: 'partnerCoursel',  // Route for partnerCoursel page
            element: <PrivateRouter element={<PartnerCoursel />} />
          },
          {
            path: 'latestNews',  // Route for latestNews page
            element: <PrivateRouter element={<LatestNews />} />
          },
          {
            path: 'ourLeadership',  // Route for OurLeadershipTeam page
            element: <PrivateRouter element={<OurLeadershipTeam />} />
          },
          { 
            path: 'Admin_Dashboard_Applications', 
            element: <PrivateRouter element={<Dashboard />} /> 
          },
          { 
            path: 'Post_job_Offers', 
            element: <PrivateRouter element={<PostJob />} /> 
          },
          { 
            path: 'Admin_Job_List_view', 
            element: <PrivateRouter element={<JobList />} /> 
          },
          { 
            path: 'Contact_Form_Dashboard', 
            element: <PrivateRouter element={<ContactFormDashboard />} /> 
          },
          { 
            path: 'Admin_Profile_page', 
            element: <PrivateRouter element={<Profile />} /> 
          },
          { 
            path: 'Admin_Profile_Edit_page', 
            element: <PrivateRouter element={<EditProfile />} /> 
          },
          { 
            path: 'Admin_Profile_Dashboard', 
            element: <PrivateRouter element={<ProfileDashboard />} /> 
          },
        ]
      }
]);

export default router;