import axios from 'axios';

const BASE_URL = 'https://etplbe.etpl.cloud';

const token = localStorage.getItem("token")
// ---------------------------------------------------------------------------------------------
// Contact Form APIs
export const createContactForm = async (data) => {
  return axios.post(`${BASE_URL}/api/contact/create/`, data);
};

export const getAllContactForms = async () => {
  return axios.get(`${BASE_URL}/api/contact/list/`);
};

export const getUpdateOrDeleteContactForm = async (id, method, data = {}) => {
  return axios({
    method: method,
    url: `${BASE_URL}/api/contact/${id}/`,
    data: data,
  });
};


// ---------------------------------------------------------------------------------------------
// Candidates Application APIs
export const getAllOrSubmitJobApplication = async (method, data = {}) => {
  return axios({
    method: method,
    url: `${BASE_URL}/api/job-application/`,
    data: data,
  });
};

export const deleteJobApplication = async (id) => {
  return axios.delete(`${BASE_URL}/api/job-application/${id}/`);
};

export const archiveOrUnarchiveJobApplication = async (id, data) => {
  return axios.patch(`${BASE_URL}/api/job-application/${id}/archive/`, data);
};


// ---------------------------------------------------------------------------------------------
// Careers APIs
export const getAllOrPostJob = async (method, data = {}) => {
  return axios({
    method: method,
    url: `${BASE_URL}/api/jobs/`,
    data: data,
  });
};

export const getUpdateOrDeleteJob = async (id, method, data = {}) => {
  return axios({
    method: method,
    url: `${BASE_URL}/api/jobs/${id}/`,
    data: data,
  });
};


// ---------------------------------------------------------------------------------------------
// Authentication APIs
export const registerUser = async (data) => {
  return axios.post(`${BASE_URL}/api/Auth/register/`, data);
};

export const verifyOTP = async (data) => {
  return axios.post(`${BASE_URL}/api/Auth/verify-otp/`, data);
};

export const ResendOTP = async (data) => {
  return axios.post(`${BASE_URL}/api/Auth/resend-otp/`, data);
};

export const loginUser = async (data) => {
  return axios.post(`${BASE_URL}/api/Auth/login/`, data);
};

export const forgotPassword = async (data) => {
  return axios.post(`${BASE_URL}/api/Auth/forgot-password/`, data);
};

export const resetPassword = async (data) => {
  return axios.post(`${BASE_URL}/api/Auth/reset-password/${token}/`, data);
};

export const logoutUser = async () => {
  return axios.post(`${BASE_URL}/api/logout/`);
};

// Define the changePassword function outside the component
export const changePassword = async (data, config) => {
  return axios.post(`${BASE_URL}/api/Auth/change-password/`, data, config);
};


export const deleteUser = async (id) => {
  return axios.post(`${BASE_URL}/api/Auth/users/${id}/delete/`);
};


// ---------------------------------------------------------------------------------------------
// User Profile APIs
export const getUpdateOrDeleteProfile = async (id, method, data = {}) => {
  return axios({
    method: method,
    url: `${BASE_URL}/api/Profile/profiles/${id}/`,
    data: data,
  });
};

export const getAllProfiles = async () => {
  return axios.get(`${BASE_URL}/api/Profile/profiles/`);
};


// ---------------------------------------------------------------------------------------------
// Home Page APIs
export const getAllOrAddPartner = async (method, data = {}) => {
  return axios({
    method: method,
    url: `${BASE_URL}/api/HomePage/partners/`,
    data: data,
  });
};

export const getUpdateOrDeletePartner = async (id, method, data = {}) => {
  return axios({
    method: method,
    url: `${BASE_URL}/api/HomePage/partners/${id}/`,
    data: data,
  });
};

export const getAllOrAddNews = async (method, data = {}) => {
  return axios({
    method: method,
    url: `${BASE_URL}/api/HomePage/news/`,
    data: data,
  });
};

export const getUpdateOrDeleteNews = async (id, method, data = {}) => {
  return axios({
    method: method,
    url: `${BASE_URL}/api/HomePage/news/${id}/`,
    data: data,
  });
};


// ---------------------------------------------------------------------------------------------
// About Us Page APIs
export const getAllOrAddTeamMember = async (method, data = {}) => {
  return axios({
    method: method,
    url: `${BASE_URL}/api/AboutPage/teams/`,
    data: data,
  });
};

export const getUpdateOrDeleteTeamMember = async (id, method, data = {}) => {
  return axios({
    method: method,
    url: `${BASE_URL}/api/AboutPage/teams/${id}/`,
    data: data,
  });
};






// ----------------- Sandy Code -------------------------
// Partners Related API Operations
export const GetPartnerdataAndAddPartnerdata = async (method, data) => {
    return axios({
        method: method,
        url: `${BASE_URL}/api/HomePage/partners/`,
        data: data
    });
};

export const UpdatePartnerdataAndDeletePartnerdata  = async (method,id,data) => {
    return axios({
        method: method,
        url: `${BASE_URL}/api/HomePage/partners/${id}/`,
        data: data
    });
};

//  Lates News Related API Operations
export const GetlatestAndAddlatestnews = async (method, data) => {
    return axios({
        method: method,
        url: `${BASE_URL}/api/HomePage/news/`,
        data: data
    });
};

export const UpdatelatestAndDeletelatestnews = async (method,id,data) => {
    return axios({
        method: method,
        url: `${BASE_URL}/api/HomePage/news/${id}/`,
        data: data
    });
};
//  Our LeaderShip Team Related API Operations
export const GetOurLeadershipAndAddOurLeadership = async (method, data) => {
    return axios({
        method: method,
        url: `${BASE_URL}/api/AboutPage/teams/`,
        data: data
    });
};

export const UpdateOurLeadershipAndDeleteOurLeadership = async (method,id,data) => {
    return axios({
        method: method,
        url: `${BASE_URL}/api/AboutPage/teams/${id}/`,
        data: data
    });
};