import React, { useState } from "react";
import LogoImage from "../../../images/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../services/EtplApi";
import { BsArrowBarLeft } from "react-icons/bs";

const ForgotPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    email: "",
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      // Call the forgotPassword API
      const response = await forgotPassword(formData);      
      localStorage.setItem('token', response.data.token)
      // Handle the response
      if (response.error) {
        setErrorMessage(response.message || "Failed to send reset link. Please try again.");
        // Optionally, you can redirect the user to a different page after a successful submission
      } else {
        setSuccessMessage(response.message || "Password reset link sent successfully! Please Check Your Email");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      console.error("Forgot Password Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-black h-[100vh] w-full flex items-center justify-center flex-col gap-10">
      <div>
        <img src={LogoImage} alt="Logo" className="w-[280px] md:w-[320px]" />
      </div>
      <div>
        <form
          onSubmit={handleSubmit}
          className="bg-white relative border-t-4 border-t-red-500 border-b-4 border-b-red-600 p-3 rounded-md w-[300px] sm:w-[350px] md:w-[450px] min-h-[32vh]"
        >
          {/* Display success or error messages */}
          

          <h1 className="text-pink-600 text-[23px] mt-1 text-center tracking-wider font-bold font-serif">
            Forgot Password
          </h1>
          <p className="mt-3 text-center text-gray-400">
            Enter Your email to Get Reset Link
          </p>
          {successMessage && (
            <div className="bg-green-100 border text-[13px] text-center border-green-400 text-green-700 p-2 rounded mb-2">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="bg-red-100  border text-center border-red-400 text-red-700 p-2 rounded mb-2">
              {errorMessage}
            </div>
          )}
          <div className="top-5 left-5 absolute ">
            <Link to="/ETPL_Admin_Login">
              <BsArrowBarLeft className="text-pink-600 text-[25px]"/>
            </Link>
          </div>
          <div className="mt-2 flex gap-2 flex-col">
            <label htmlFor="email" className="text-pink-800">Email:</label>
            <input
              type="email"
              name="email"
              className="bg-pink-200"
              id="email"
              placeholder="Enter Your Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mt-6 flex items-center justify-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`bg-red-400 px-5 py-2 font-serif font-bold rounded-lg transition-all duration-300 ease-in-out ${
                isSubmitting
                  ? "bg-gray-400 text-white cursor-not-allowed"
                  : "hover:bg-pink-700"
              }`}
            >
              {isSubmitting ? "Sending..." : "Send Link"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;