import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import SuccessImage from "../../images/Careers page/Success.png";
import AttitudeImage from "../../images/Careers page/Attitude.png";
import EfficiencyImage from "../../images/Careers page/Efficiency.png";
import TrustImage from "../../images/Careers page/Trust.png";
import BgImage from '../../images/back3.jpg';


const descriptions = {
  Trust: {
    text: "A sense of security and rely on each other without fear of exploitation, manipulation or betrayal (Key Aspects: Reliability, Consistency, Competence, Accountability, and Confidentiality).",
    image: TrustImage,
  },
  Efficiency: {
    text: "Ability to accomplish a task or produce a result with MINIMUM time, effort, and resources (Key Aspects: Defined processes, SOP, Time Management, Resource Management, Cost Effectiveness, Productivity, Innovation, Target Driven, Automation, Technology, Continuous Improvement).",
    image: EfficiencyImage,
  },
  Attitude: {
    text: "Attitude of an organization is nothing but a collective behavior and approach that the leadership and employees have in their work and interaction. (Key Aspects: Positive Outlook, Collaboration, Team Work, Customer Focus, Adaptability, Growth Mindset, Respect, Leadership, Role Model, etc).",
    image: AttitudeImage,
  },
  Meritocracy: {
    text: "Our commitment to promoting and partnering organizations, its products and services, and even individuals based on real performance, quality, abilities, and skills rather than other factors like seniority or favoritism. (Key Aspects: Merit-Based Advancement, Equality of Opportunity, Performance Evaluation, Reward System).",
    image: SuccessImage,
  },
};

export default function CoreValues() {
  const [activeKey, setActiveKey] = useState("Trust");

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveKey((prevKey) => {
        const keys = Object.keys(descriptions);
        const nextIndex = (keys.indexOf(prevKey) + 1) % keys.length;
        return keys[nextIndex];
      });
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col md:flex-row items-center justify-center gap-10 min-h-[80vh] text-white p-6">
      {/* Left Side - Buttons */}
      <div data-aos="fade-down" className="flex flex-col gap-4 w-full items-center justify-center md:w-1/3 mx-auto md:mx-0">
        {Object.keys(descriptions).map((key) => (
          <button
            key={key}
            onClick={() => setActiveKey(key)}
            className={`py-3 px-8 rounded-lg w-[70%] slide_right font-semibold transition-all duration-300 text-center ${
              activeKey === key
                ? "bg-blue-600 text-white"
                : "hover:bg-gray-600 bg-gray-800 text-gray-300"
            }`}
          >
          {key
            .split(" ") // Split words
            .map((word, index) => (
              <span key={index}>
                <span className="text-pink-600">{word.charAt(0)}</span>
                {word.slice(1)}{" "}
              </span>
            ))}
        </button>  
        ))}
      </div>

      {/* Right Side - Description & Image */}
      <motion.div
        key={activeKey}
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -50 }}
        transition={{ duration: 0.5 }}
        className="w-full md:w-2/3 flex relative bg-transparent flex-col  border-2 border-pink-500 mt-5 md:mt-0 p-6 items-center justify-center gap-4 text-center md:text-left rounded-xl shadow-lg"
      >
        <img src={BgImage} alt="" className="absolute w-full blur-sm h-full rounded-xl"/>
        <div className="w-full flex items-center z-10 justify-center">
          <img
            src={descriptions[activeKey].image}
            alt={activeKey}
            className="max-w-[200px] sm:max-w-[250px] md:max-w-[200px] h-auto"
          />
        </div>
        <h2 className="text-2xl font-bold z-10">{activeKey}</h2>
        <p className="text-gray-300 w-[90%] z-10 sm:w-[80%] md:w-full">
          {descriptions[activeKey].text}
        </p>
      </motion.div>
    </div>
  );
}
