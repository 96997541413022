import { getAllContactForms, getUpdateOrDeleteContactForm } from '../../../services/EtplApi'; // Adjust the import path as needed
import React, { useEffect, useState } from 'react'
import CanvasCursor from '../../../components/CustomCursor/CanvasCursor';
import SideNavbar from '../../../components/AdminHeader/Side Navbar/SideNavbar';

const ContactFormDashboard = () => {
    const [ContactForm, setContactForm] = useState([]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [buttonvisible, setButtonvisible] = useState(true);
  useEffect(() => {
    fetchContactForm();
  }, []);

  const fetchContactForm = async () => {
    try {
      // Use the getAllContactForms function
      const response = await getAllContactForms();
      setContactForm(response.data); // Assuming the data is in response.data
    } catch (error) {
      console.error("Error fetching ContactForm:", error);
    }
  };
  
  const handleDelete = async (id) => {
    try {
      // Use the getUpdateOrDeleteContactForm function with the 'delete' method
      await getUpdateOrDeleteContactForm(id, 'delete');
      fetchContactForm(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting Contact:", error);
    }
  };

  // Format date to "DD-MM-YYYY"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

    // Functions to handle sidebar opening and closing
    const shownavigation = () => {
      setIsSidebarOpen(true);
      setButtonvisible(false);
    };
  
    const hidenavigation = () => {
      setIsSidebarOpen(false);
      setButtonvisible(true);
    };
  return (
    <div className="p-8 bg-black text-white">
        <CanvasCursor />
        <SideNavbar
        shownavigation={shownavigation}
        hidenavigation={hidenavigation}
        isSidebarOpen={isSidebarOpen}
        isbuttonvisible={buttonvisible}
      />
      <h2 className="text-3xl font-bold text-pink-600 mb-8">Contact Form</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-900 rounded-lg overflow-hidden">
          <thead className="bg-pink-600">
            <tr>
              <th className="px-6 py-4 text-left text-sm font-medium text-white  whitespace-nowrap">
                First Name
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white whitespace-nowrap">
                Last Name
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Email
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Phone Number
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Subject
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Message
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white whitespace-nowrap">
                Submitted On
              </th>
              <th className="px-6 py-4 text-left text-sm font-medium text-white">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {ContactForm.map((Contact) => (
              <tr key={Contact.id} className="hover:bg-gray-800">
                <td className="px-6 py-4 text-sm text-gray-300">
                  {Contact.first_name}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300">
                  {Contact.last_name}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300">
                <a href={`mailto:${Contact.email}`}>{Contact.email}</a>
                </td>
                <td className="px-6 py-4 text-sm text-gray-300">
                  {Contact.phone_number}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300  min-w-[300px]">
                  {Contact.subject}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 min-w-[300px]">
                  {Contact.message}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 whitespace-nowrap">
                  {formatDate(Contact.created_at)}
                </td>
                <td className="px-6 py-4 text-sm whitespace-nowrap">
                  <button
                    onClick={() => handleDelete(Contact.id)}
                    className="text-red-600 hover:text-red-700"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactFormDashboard
