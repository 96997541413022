import React from "react";
import { useEffect } from "react";
import "./HomeCss/Home.css";
import ScrollAnimation from "./ScrollAnimation";
import AOS from "aos";
import "aos/dist/aos.css";
// import homeimg from "../images/home-img.png";
// import homeimg1 from "../images/home-img1.png";
// import home1 from "../images/1.png";
// import home2 from "../images/2.png";
// import home3 from "../images/3.png";
// import home4 from "../images/4.png";
// import home5 from "../images/5.png";
import abtgif from "../../images/Abt-Gif.gif";
import WD from "../../images/WD.png";
import DS from "../../images/DS.png";
import ERP from "../../images/ERP.png";
import SMM from "../../images/SMM.png";
import DM from "../../images/DM.png";
import layer1 from "../../images/layer-1.png";
import layer2 from "../../images/layer-2.png";
// import temp1 from "../images/temp-1.jpeg";
// import temp2 from "../images/temp-2.png";
// import temp3 from "../images/temp-3.jpeg";
import mission from "../../images/mission.png";
import vision from "../../images/vision.png";
import foundation from "../../images/foundation.png";
import core from "../../images/core.png";
import HeaderCarousel from "./CustomeAnimation/HeaderCarousel";
import { Link } from "react-router-dom";
import CanvasCursor from "../../components/CustomCursor/CanvasCursor";
import PartnerCompany from "./PartnerCompany";
import LatestNews from "./LatestNews";
import BgImageHome from '../../images/back1.jpg'

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div className="bg-transparent">
      <img src={BgImageHome} className='w-full h-[100vh] absolute ' alt="" />
      <CanvasCursor />
      <ScrollAnimation />
      <HeaderCarousel />
      {/* <div className="service-container mt-5">
        <div className="box-cont">
          <div className="serv-box anim">
            <img src={home1} alt=""></img>
            <p>Web Development</p>
          </div>
          <div className="serv-box anim">
            <img src={home3} alt=""></img>
            <p>Enterprise Resource Management</p>
          </div>
          <div className="serv-box anim">
            <img src={home4} alt=""></img>
            <p>Data Server</p>
          </div>
          <div className="serv-box anim">
            <img src={home2} alt=""></img>
            <p>Social Media Management</p>
          </div>
          <div className="serv-box anim">
            <img src={home5} alt=""></img>
            <p>Digital Marketing Tools</p>
          </div>
        </div>
      </div> */}
      {/* About Page Starts */}

      <div className="about">
        <h1 data-aos="fade-up" className=" text-pink-600">
          Crafting Your Digital Success Story
        </h1>
        <div className="abt-container">
          <div className="abt-img">
            <img src={abtgif} width="650px" alt="about-img1" />
          </div>
          <div className="abt-text text-white" data-aos="fade-up">
            <p>
              Emayamtech is where innovation meets excellence in web solutions.
              With us, you&apos;ll find a team dedicated to elevating your
              online presence through tailored services designed to meet your
              unique needs. From stunning website designs to seamless e-commerce
              integration and robust web applications, we&apos;re committed to
              transforming your digital vision into reality.
              <br />
              <br />
              Our mission is clear: to revolutionize the digital landscape
              through innovative web solutions, empowering businesses to thrive
              in today&apos;s ever-evolving digital age. We envision ourselves
              as the premier provider of cutting-edge web solutions, recognized
              globally for our creativity, reliability, and unwavering
              commitment to the success of our clients.
              <br />
              <br />
              At the core of Emayamtech lies a foundation built on tech passion,
              excellence, and customer satisfaction. We value enduring
              partnerships, fostering growth, and making a positive digital
              impact. With Emayamtech, you&apos;re not just a client;
              you&apos;re a partner in our journey towards digital greatness.
              Let&apos;s embark on this journey together and create something
              truly extraordinary.
              <Link to="/contact" className="rounded-3xl">
                CONNECT WITH US
              </Link>
            </p>
          </div>
          <div className="abt-box"></div>
        </div>
      </div>
      {/* About Page ends */}
      {/* Service Page Starts */}
      <div className="service">
        <h1 data-aos="fade-up" className="text-pink-600">
          Our Comprehensive SaaS Solutions
        </h1>
        <div className="serv-container text-white">
          <div className="containe container-1" data-aos="fade-up">
            <div className="bo bo-1">
              <div className="flip-bo">
                <div className="flip-box-inne">
                  <div className="flip-box-fron  rounded-lg">
                    <div className="img-container">
                      <img src={WD} alt="SaaS Development"></img>
                      <h3 className="text-[15px]">SaaS PRODUCT DEVELOPMENT</h3>
                    </div>
                  </div>
                  <div className="flip-box-bac rounded-lg">
                    <div className="flip-bac">
                      <h3 className="text-[15px]">SaaS PRODUCT DEVELOPMENT</h3>
                      <p>
                        We specialize in building scalable, secure, and
                        user-friendly SaaS products tailored to your business
                        needs. From ideation to deployment, our end-to-end
                        development process ensures your product stands out in
                        the market.
                      </p>
                      <p>
                        <Link
                          to="/services"
                          className="transition-all duration-300 ease-in-out"
                        >
                          READ MORE
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bo box-2">
              <div className="flip-bo">
                <div className="flip-box-inne">
                  <div className="flip-box-fron rounded-lg">
                    <div className="img-container">
                      <img src={ERP} alt="SaaS Integration"></img>
                      <h3>SaaS INTEGRATION</h3>
                    </div>
                  </div>
                  <div className="flip-box-bac rounded-lg">
                    <div className="flip-bac">
                      <h3>SaaS INTEGRATION</h3>
                      <p>
                        Seamlessly integrate your SaaS product with existing
                        systems, third-party APIs, and tools. Our integration
                        solutions ensure smooth data flow, enhanced
                        functionality, and a unified user experience.
                      </p>
                      <Link
                        to="/services"
                        className="transition-all duration-300 ease-in-out"
                      >
                        READ MORE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bo box-3">
              <div className="flip-bo">
                <div className="flip-box-inne">
                  <div className="flip-box-fron rounded-lg">
                    <div className="img-container">
                      <img src={DS} alt="Cloud Infrastructure"></img>
                      <h3>CLOUD INFRASTRUCTURE</h3>
                    </div>
                  </div>
                  <div className="flip-box-bac rounded-lg">
                    <div className="flip-bac">
                      <h3>CLOUD INFRASTRUCTURE</h3>
                      <p>
                        Build and deploy your SaaS product on a robust,
                        scalable, and secure cloud infrastructure. We offer
                        tailored solutions for hosting, storage, and server
                        management to ensure optimal performance.
                      </p>
                      <Link
                        to="/services"
                        className="transition-all duration-300 ease-in-out"
                      >
                        READ MORE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="containe container-2" data-aos="fade-up">
            <div className="bo box-4">
              <div className="flip-bo">
                <div className="flip-box-inne">
                  <div className="flip-box-fron rounded-lg">
                    <div className="img-container">
                      <img src={SMM} alt="SaaS Analytics"></img>
                      <h3>SaaS ANALYTICS</h3>
                    </div>
                  </div>
                  <div className="flip-box-bac rounded-lg">
                    <div className="flip-bac">
                      <h3>SaaS ANALYTICS</h3>
                      <p>
                        Gain actionable insights into your SaaS product’s
                        performance with our advanced analytics tools. Track
                        user behavior, monitor KPIs, and optimize your product
                        for better engagement and retention.
                      </p>
                      <Link
                        to="/services"
                        className="transition-all duration-300 ease-in-out"
                      >
                        READ MORE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bo box-4">
              <div className="flip-bo">
                <div className="flip-box-inne">
                  <div className="flip-box-fron rounded-lg">
                    <div className="img-container">
                      <img src={DM} alt="SaaS Automation"></img>
                      <h3>SaaS AUTOMATION</h3>
                    </div>
                  </div>
                  <div className="flip-box-bac rounded-lg">
                    <div className="flip-bac">
                      <h3>SaaS AUTOMATION</h3>
                      <p>
                        Automate repetitive tasks and workflows within your SaaS
                        product to improve efficiency and reduce operational
                        costs. Our automation solutions are designed to enhance
                        productivity and user satisfaction.
                      </p>
                      <Link
                        to="/services"
                        className="transition-all duration-300 ease-in-out"
                      >
                        READ MORE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Service Page Ends */}
      {/* Book and appointment starts */}
      <div className="book">
        <div className="book-contain">
          <div className="book-img" data-aos="fade-up">
            <img className="bok-img" src={layer1} alt=""></img>
            <img className="bok-img" src={layer2} alt=""></img>
          </div>
          <div className="book-content text-white" data-aos="fade-up">
            <h1 className="book-clr text-center text-pink-600" data-aos="fade-up">
              Step into the Future of SaaS Innovation
            </h1>
            <p className="book-clr text-justify" data-aos="fade-up">
              Ready to transform your business with cutting-edge SaaS solutions?
              Book a consultation with Emayam Tech Private Limited today and
              discover how our powerful, scalable, and user-friendly products
              can drive your success.
              <br />
              <br />
              From seamless integrations to robust cloud infrastructure, we’re
              here to help you build, deploy, and scale smarter. Let’s work
              together to create something extraordinary—schedule your
              appointment now and take the first step towards revolutionizing
              your digital landscape!
            </p>
            <Link
              to="/Contact"
              className="bg-[#a53892] p-3 whitespace-nowrap rounded-3xl hover:bg-pink-500 transition-all ease-in-out duration-300"
            >
              Book A Consultation
            </Link>
          </div>
        </div>
      </div>
      {/* Book and appointment ends */}
      {/* Template starts */}
      {/* <div className="template-containers">
        <h1 data-aos="fade-up" className=" text-pink-600">Explore Our Portfolio</h1>
        <div className="templat">
          <div className="temp" data-aos="fade-up">
            <Link to="https://www.vconnectexperts.com/" target="_blank">
              <img src={temp1} className="temp-img" alt=""></img>
            </Link>
          </div>
          <div className="temp" data-aos="fade-up">
            <Link to="https://www.emartxpress.in/" target="_blank">
              <img src={temp2} className="temp-img" alt=""></img>
            </Link>
          </div>
          <div className="temp" data-aos="fade-up">
            <Link to="https://whimshy.com/" target="_blank">
              <img src={temp3} className="temp-img" alt=""></img>
            </Link>
          </div>
        </div>
        <Link
          to="/Resources"
          className="a transition-all ease-in-out duration-300"
        >
          View More
        </Link>
      </div> */}
      {/* Template ends */}

      {/* mission starts */}
      <div className="mission line-height text-white">
        <h1 data-aos="fade-up" className="text-pink-600">
          DEFINING OUR PURPOSE
        </h1>
        <div className="mission-container">
          <div className="w-full CommonDIv flex items-center justify-center flex-wrap">
            <div className="mission-1 rounded-lg" data-aos="fade-up">
              <img src={mission} alt="Mission"></img>
              <h2>Mission</h2>
              <p>
                To revolutionize the SaaS industry by delivering innovative,
                scalable, and user-centric products that empower businesses to
                thrive in the digital era.
              </p>
            </div>
            <div className="mission-1 rounded-lg" data-aos="fade-up">
              <img src={vision} alt="Vision"></img>
              <h2>Vision</h2>
              <p>
                To become a global leader in SaaS product development,
                recognized for our cutting-edge solutions, unwavering
                reliability, and commitment to driving client success.
              </p>
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-wrap">
            <div className="mission-1 rounded-lg" data-aos="fade-up">
              <img src={foundation} alt="Foundation"></img>
              <h2>Foundation</h2>
              <p>
                At Emayam Tech Private Limited, we are built on a foundation of
                innovation, technical expertise, and a relentless focus on
                customer satisfaction. Our passion for technology drives
                everything we do.
              </p>
            </div>
            <div className="mission-1 rounded-lg" data-aos="fade-up">
              <img src={core} alt="Core Values"></img>
              <h2>Our Core Values</h2>
              <p>
                Innovation, collaboration, integrity, and a commitment to
                creating lasting value for our clients and partners.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* mission ends */}
      <PartnerCompany />
      <LatestNews />
    </div>
  );
};

export default Home;
