import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// import { getAllOrAddPartner } from "../../services/EtplApi";
import { partners } from '../../utils/PartnerCompany/PartnerCompany';


const PartnerCompany = () => {
  // const [partners, setPartners] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  // const BASE_URL = "https://etplbe.etpl.cloud/";
  const scrollRef = useRef(null);
  const containerRef = useRef(null);
  const scrollSpeedRef = useRef(1);
  

  // useEffect(() => {
  //   const fetchPartners = async () => {
  //     try {
  //       const response = await getAllOrAddPartner("GET");
  //       setPartners(response.data);
  //     } catch (error) {
  //       console.error("Error fetching partners:", error);
  //       setError("Failed to fetch partner data. Please try again later.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchPartners();
  // }, []);
  
  const animationFrameRef = useRef(null);

  useEffect(() => {
    if (partners.length > 0) {
      const container = containerRef.current;
      const scrollContent = scrollRef.current;
  
      // Clone content for infinite scrolling effect
      scrollContent.innerHTML += scrollContent.innerHTML;
  
      const scrollAnimation = () => {
        if (container.scrollLeft >= scrollContent.scrollWidth / 2) {
          container.scrollLeft = 0;
        } else if (container.scrollLeft <= 0) {
          container.scrollLeft = scrollContent.scrollWidth / 2;
        }
        container.scrollLeft += scrollSpeedRef.current;
        animationFrameRef.current = requestAnimationFrame(scrollAnimation);
      };
  
      animationFrameRef.current = requestAnimationFrame(scrollAnimation);
  
      // Reverse scroll direction on hover
      const handleMouseEnter = () => {
        scrollSpeedRef.current = -0.5; // Slow reverse scrolling
      };
      const handleMouseLeave = () => {
        scrollSpeedRef.current = 1; // Normal forward scrolling
      };
  
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);
  
      return () => {
        cancelAnimationFrame(animationFrameRef.current);
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [partners]);
  

  // if (loading) return <div>Loading partners...</div>;
  // if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="partners line-height relative overflow-hidden w-full py-10">
      <h1 data-aos="fade-up" className="text-pink-600 text-center text-2xl font-bold">
        OUR PARTNER
      </h1>
      <p data-aos="fade-up" className="text-center max-w-2xl mx-auto mt-2 text-gray-600">
        At EmayamTech, we deeply value the relationships we have with our partners. We believe in the power of collaboration and recognize that our success is intricately tied to the success of our partners.
      </p>

      {/* Infinite Scrolling Wrapper */}
      <div className="partner-box relative w-full mt-6 overflow-hidden" ref={containerRef}>
        <div className="flex items-center gap-10" ref={scrollRef}>
          {/* {partners.map((partner, index) => (
            <div className="partner-1 flex-shrink-0" key={index}>
              <Link to={partner.link} target="_blank">
                <img
                  src={`${BASE_URL}${partner.logo}`}
                  width="160px"
                  alt={partner.Name}
                  className="hover:scale-110 transition-transform duration-300"
                />
              </Link>
            </div>
          ))} */}
          {partners.map((partner, index) => (
            <div className="partner-1 flex-shrink-0 text-center" key={index}>
              <Link to={partner.link} target="_blank">
                <img
                  src={partner.logo} // Use logo from utils (fallback applied there)
                  width="160px"
                  alt={partner.name}
                  className="hover:scale-110 transition-transform duration-300 rounded-full mx-auto"
                />
              </Link>
              <h4 className="mt-2 font-bold">{partner.name}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnerCompany;
