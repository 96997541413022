import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { registerUser } from "../../../services/EtplApi"; // Import the registerUser API function
import "./RegisterCss/Register.css";
import { IoMdCloseCircle } from 'react-icons/io';


const Register = ({ onClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    last_name: "",
    first_name: "",
    confirm_password: "",
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");

    // Check if passwords match
    if (formData.password !== formData.confirm_password) {
      setErrorMessage("Passwords do not match");
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await registerUser(formData);
      localStorage.setItem("email", formData.email)
      if (response.data) {
        setSuccessMessage("Account Created...");
        setTimeout(() => {
          onClose()
        }, 2000); // Redirect to login page after 2 seconds
        window.location.reload();
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Registration failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 z-50 h-[100vh] w-full flex items-center justify-center flex-col gap-10">
      {/* <div>
        <img src={LogoImage} alt="Logo" className="w-[96%] md:w-[320px]" />
      </div> */}
      <div>
        <form
          onSubmit={handleSubmit}
          className="bg-white relative border-t-4 border-t-red-500 border-b-4 border-b-red-600 p-3 rounded-md w-[300px] sm:w-[350px] md:w-[450px] max-h-[80vh] min-h-[55vh]"
        >
          {/* Display success or error messages */}
          {successMessage && (
            <div className="bg-green-100 border text-center border-green-400 text-green-700 px-4 py-3 rounded mb-4">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="bg-red-100 border text-center border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {errorMessage}
            </div>
          )}

          <h1 className="text-pink-600 text-[23px] mt-1 text-center tracking-wider font-bold font-serif">
            Admin Register
          </h1>
          
          <IoMdCloseCircle
              className="absolute cursor-pointer transition-all ease-in-out duration-300 hover:text-red-800 text-pink-600 text-[30px] top-5 right-3"
              onClick={onClose}
          />
          <div className="flex justify-center gap-3 mt-2 flex-col">
            <label htmlFor="username" className="text-pink-800">
              User Name:
            </label>
            <input
              type="text"
              name="username"
              placeholder="Enter Your User Name"
              value={formData.username}
              onChange={handleInputChange}
              className="bg-pink-200"
              required
            />
          </div>
          <div className="flex justify-center gap-3 mt-2 flex-col">
            <label htmlFor="email" className="text-pink-800">
              Email:
            </label>
            <input
              type="email"
              name="email"
              placeholder="Enter Your Email"
              value={formData.email}
              onChange={handleInputChange}
              className="bg-pink-200"
              required
            />
          </div>
          <div className="sm:flex block justify-between item-center">
            <div className="flex sm:w-[49%] md:w-[49%] justify-center gap-3 mt-2 flex-col">
              <label htmlFor="first_name" className="text-pink-800">
                First Name:
              </label>
              <input
                type="text"
                name="first_name"
                placeholder="Enter Your First Name"
                value={formData.first_name}
                onChange={handleInputChange}
                className="bg-pink-200"
                required
              />
            </div>
            <div className="flex sm:w-[49%] md:w-[49%] justify-center gap-3 mt-2 flex-col">
              <label htmlFor="last_name" className="text-pink-800">
                Last Name:
              </label>
              <input
                type="text"
                name="last_name"
                placeholder="Enter Your Last Name"
                value={formData.last_name}
                onChange={handleInputChange}
                className="bg-pink-200"
                required
              />
            </div>
          </div>
          <div className="sm:flex block justify-between item-center">
            <div className="flex relative sm:w-[49%] md:w-[49%] justify-center gap-3 mt-2 flex-col">
              <label htmlFor="password" className="text-pink-800">
                Password:
              </label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter Your Password"
                value={formData.password}
                onChange={handleInputChange}
                className="bg-pink-200"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="text-pink-900 absolute top-[65px] right-3"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            <div className="flex relative sm:w-[49%] md:w-[49%] justify-center gap-3 mt-2 flex-col">
              <label htmlFor="confirm_password" className="text-pink-800 whitespace-nowrap">
                Confirm Password:
              </label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                placeholder="Enter Your Confirm Password"
                value={formData.confirm_password}
                onChange={handleInputChange}
                className="bg-pink-200"
                required
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="text-pink-900 absolute top-[65px] right-3"
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`bg-red-400 w-[60%] py-2 font-serif font-bold rounded-lg transition-all duration-300 ease-in-out ${
                isSubmitting
                  ? "bg-gray-400 text-white cursor-not-allowed"
                  : "hover:bg-pink-700"
              }`}
            >
              {isSubmitting ? "Creating..." : "Create Account"}
            </button>
          </div>
          <div className="mt-4">
            <p className="text-black text-center font-serif">
              Already Have An Account?{" "}
              <Link
                to="/ETPL_Admin_Login"
                className="underline text-blue-400 transition-all duration-300 ease-in-out hover:text-blue-800"
              >
                Login
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;