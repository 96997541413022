import React from "react";
import CareerImage from "../../images/Careers page/Careers.jpg";
import bgImage1 from "../../images/Careers page/bgImage.png";
import EyeIcon from "../../images/Careers page/vision_7531552.png";
import GroupIcon from "../../images/Careers page/account_2996808.png";
import PeopleIcon from "../../images/Careers page/group_921296.png";
import RocketIcon from "../../images/Careers page/startup_1067256.png";
import "./CareerCss/CareerCss.css";
import CanvasCursor from "../../components/CustomCursor/CanvasCursor";
import Jobs from "./Jobs";


const careerDetails = [
  {
    icon: EyeIcon,
    title: "Career Growth",
    description: "We are a fast-growing company.",
  },
  {
    icon: GroupIcon,
    title: "HealthCare",
    description: "A good healthy condition will make you feel positive.",
  },
  {
    icon: PeopleIcon,
    title: "Great People",
    description: "We appreciate and motivate brilliant ideas and skills.",
  },
  {
    icon: RocketIcon,
    title: "Social Commitment",
    description: "We love to contribute and stand for any social cause.",
  },
];

const CareerPage = () => {
  return (
    <div className="w-full mb-5 text-white">
      <CanvasCursor />
      <div className="relative">
        <img
          src={CareerImage}
          alt="Career"
          className="w-full md:w-[80%] shadow-md shadow-white rounded-lg h-[50vh] md:h-[80vh] mr-auto ml-auto"
        />
        <div className="absolute p-3 md:p-5 w-[90%] md:w-[80%] lg:w-[50%] backdrop-blur-sm bg-[rgba(7,7,7,0.3)] rounded-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className="text-center text-[20px] sm:text-[25px] md:text-[35px] text-pink-600"  >
            Join Our Team And Unleash Your Potential
          </h1>
          <p className="text-[14px] sm:text-[18px] md:text-[25px] mt-3 md:mt-5 text-justify font-bold"  >
            Start Your Career Journey With Us And Unlock Endless Opportunities
            For Growth, Innovation, And Success. Be The Difference And Create A
            Winning Career.
          </p>
        </div>
      </div>

      <div className="w-[80%] md:w-[60%] mt-5 block mr-auto ml-auto">
        <h1 className="p-4 text-pink-600 text-[34px] text-center"  >
          Company Culture
        </h1>
        <p className="mt-3 text-[20px] text-center">
          We love to make complex things look simple. We have a work process
          that suits this concept of workflow. Taking up Challenges make you
          sharper and confident. At webCastle everyone will be facing challenges
          which will make them a better professional and also as person.
        </p>
      </div>

      <div className="flex mt-5 items-center justify-center flex-wrap gap-5">
        {careerDetails.map((item, index) => (
          <div
            key={index}
            className="bg-white hover:scale-110 transition-all duration-300 ease-in-out border-t-4 border-red-600 shadow-pink-600 text-black relative min-h-[300px] rounded-lg gap-3 flex flex-col justify-center items-center w-[250px] p-3 shadow-md"
          >
            <img
              src={bgImage1}
              className="absolute w-[110px] h-[110px] top-7"
              alt=""
            />
            <img
              src={item.icon}
              alt={item.title}
              className="w-[40px] h-[40px] z-10"
            />
            <h2 className="mt-4 text-pink-500 text-xl font-bold">
              {item.title}
            </h2>
            <p className="mt-2 text-black text-center">{item.description}</p>
          </div>
        ))}
      </div>
      <Jobs />
    </div>
  );
};

export default CareerPage;
