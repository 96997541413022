import React, { useState } from "react";
import { createContactForm } from '../../services/EtplApi'; // Adjust the import path as needed
import "./ContactCss/Contact.css";
import locationImg from "../../images/location.png";
import emailImg from "../../images/email.png";
import phoneImg from "../../images/phone.png";
import contactImg from "../../images/w5.png";
import CanvasCursor from "../../components/CustomCursor/CanvasCursor";
import { FaTimes } from "react-icons/fa"; // For close icon
import { motion, AnimatePresence } from "framer-motion"; // For animations

function Contact() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null); // "success" or "error"
  const [submissionMessage, setSubmissionMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const newErrors = {};
    if (!firstName) newErrors.firstName = "First Name is required";
    if (!lastName) newErrors.lastName = "Last Name is required";
    if (!email) newErrors.email = "Email is required";
    if (!phone) newErrors.phone = "Phone Number is required";
    if (!subject) newErrors.subject = "Subject is required";
    if (!message) newErrors.message = "Message is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);

    try {
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phone,
        subject: subject,
        message: message,
      };

      const response = await createContactForm(formData);
      console.log("Response:", response.data);

      // Clear form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setSubject("");
      setMessage("");
      setErrors({});

      // Set success message
      setSubmissionStatus("success");
      setSubmissionMessage("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionStatus("error");
      setSubmissionMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);

      // Automatically hide the message after 3 seconds
      setTimeout(() => {
        setSubmissionStatus(null);
        setSubmissionMessage("");
      }, 3000);
    }
  };

  const closeMessage = () => {
    setSubmissionStatus(null);
    setSubmissionMessage("");
  };

  return (
    <div className="App">
      <CanvasCursor />

      {/* Submission Message */}
      <AnimatePresence>
        {submissionStatus && (
          <motion.div
            className={`submission-message ${submissionStatus}`}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
          >
            <div className="message-content">
              <div className="loader"></div>
              <span>{submissionMessage}</span>
              <button className="close-button" onClick={closeMessage}>
                <FaTimes />
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="h">
        <br />
        <h1 className="text-pink-600" style={{ textAlign: "center", fontSize: "30px" }}>
          {" "}
          CONTACT US
        </h1>
      </div>
      <br />
      {/* <Iframe /> */}
      {/* <ContactSection /> */}
      <Form
        firstName={firstName}
        lastName={lastName}
        email={email}
        phone={phone}
        subject={subject}
        message={message}
        errors={errors}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        handleInputChange={(e) => {
          const { name, value } = e.target;
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
          switch (name) {
            case "firstName":
              setFirstName(value);
              break;
            case "lastName":
              setLastName(value);
              break;
            case "email":
              setEmail(value);
              break;
            case "phone":
              setPhone(value);
              break;
            case "subject":
              setSubject(value);
              break;
            case "message":
              setMessage(value);
              break;
            default:
              break;
          }
        }}
      />
    </div>
  );
}

function Iframe() {
  return (
    <div className="iframe ">
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.191099052838!2d80.2233927!3d12.9687948!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525da45a413581%3A0xfd39dfe7579bd4de!2sEMAYAM%20TOWERS!5e0!3m2!1sen!2sin!4v1716468301740!5m2!1sen!2sin"
        width="1200"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
}

function ContactSection() {
  return (
    <div className="contact-section">
      <ContactBox
        img={locationImg}
        title="EMAYAM TECHNOLOGIES"
        address="Emayam Towers, Bhuvaneshwari Nagar, Velachery,. Chennai, Tamil Nadu 600042"
      />
      <ContactBox
        img={emailImg}
        title="sales@emayamtech.com"
        address="Email your query"
      />
      <ContactBox
        img={phoneImg}
        title="+91 8111 066 066"
        address="Monday-Saturday , 9am-7pm"
      />
    </div>
  );
}

function ContactBox({ img, title, address }) {
  return (
    <div className="contact-box">
      <center>
        <img src={img} alt="" />
        <br></br>
      </center>
      <p
        style={{
          textShadow: "2px 2px 2px black",
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        <b>{title}</b>
      </p>
      <p style={{ textShadow: "2px 2px 2px black", textAlign: "center" }}>
        <br />
        {address}
      </p>
    </div>
  );
}

function Form({
  firstName,
  lastName,
  email,
  phone,
  subject,
  message,
  errors,
  isSubmitting,
  handleSubmit,
  handleInputChange,
}) {
  return (
    <div id="form">
      <div className="send">
        {/* <label htmlFor="send" className=" text-pink-600">Contact Us</label> */}
      </div>
      <form onSubmit={handleSubmit} method="post" className="overflow-visible" name="google-sheet">
        <div className="form-container flex items-center justify-evenly">
          <div className="form-image w-[50%]" >
            <img src={contactImg} id="img" alt="Contact" className="w-[95%] h-[450px]" />
          </div>
          <div className="form-fields  min-w-[490px]">
            <div className="flex  w-[100%] items-center p-3 gap-5 justify-between input-group">
              <div className="InputDiv">
                <input
                  type="text"
                  name="firstName"
                  required
                  placeholder="First Name"
                  value={firstName}
                  onChange={handleInputChange}
                  onBlur={handleInputChange}
                  className=" w-[100%] "
                />
                {errors.firstName && <span className="error-text">{errors.firstName}</span>}
              </div>
              <div className="InputDiv">
                <input
                  type="text"
                  name="lastName"
                  required
                  placeholder="Last Name"
                  value={lastName}
                  onChange={handleInputChange}
                  onBlur={handleInputChange}
                  className=" w-[100%]"
                />
                {errors.lastName && <span className="error-text">{errors.lastName}</span>}
              </div>
            </div>
            <div className="input-group  w-[100%] p-3 gap-5 mt-3 flex items-center justify-between ">
              <div className="InputDiv">
                <input
                  type="email"
                  name="email"
                  required
                  placeholder="Email"
                  className="w-[100%]"
                  value={email}
                  onChange={handleInputChange}
                  onBlur={handleInputChange}
                />
                {errors.email && <span className="error-text">{errors.email}</span>}
              </div>
              <div className="InputDiv">
                <input
                  type="tel"
                  name="phone"
                  required
                  placeholder="Phone Number"
                  className="w-[8100]"
                  value={phone}
                  onChange={handleInputChange}
                  onBlur={handleInputChange}
                />
                {errors.phone && <span className="error-text">{errors.phone}</span>}
              </div>
            </div>
            <div className="input-group  mt-3 p-3 w-full">
              <input
                type="text"
                name="subject"
                required
                placeholder="Subject"
                className="w-full"
                value={subject}
                onChange={handleInputChange}
                onBlur={handleInputChange}
              />
              {errors.subject && <span className="error-text">{errors.subject}</span>}
            </div>
            <div className="input-group p-3  w-[100%]">
              <textarea
                rows="5"
                name="message"
                required
                placeholder="Your Message"
                className="w-[100%] bg-yellow-300"
                value={message}
                onChange={handleInputChange}
                onBlur={handleInputChange}
              ></textarea>
              {errors.message && <span className="error-text">{errors.message}</span>}
            </div>
            <div className="button1 relative h-20 w-[100%] ">
              <button
                className={`but absolute text-white font-bold right-0 ${isSubmitting ? "submitting" : ""}`}
                type="submit"
                name="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Contact;