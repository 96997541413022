import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { partners } from '../../../utils/OurPartners/OurPartners';


// const PartnerCarousel = ({ partners }) => {
const PartnerCarousel = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: false, // Disable center mode
    initialSlide: 0, // Start from the first slide
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="relative w-[95%] mx-auto">
      {/* Left Arrow */}
      <button
        className="absolute left-0 top-1/2 transform -translate-y-1/2 transition-all ease-in-out duration-200 z-10 text-3xl hover:bg-pink-600 p-2 bg-pink-400 shadow-green-600 rounded-full shadow-md"
        onClick={() => sliderRef.current.slickPrev()}
      >
        <FaAngleLeft />
      </button>
      {/* Slider */}
      <Slider ref={sliderRef} {...settings}>
        {partners.map((partner, index) => (
          <div
            key={index}
            className="text-center p-4 transition-all duration-500 ease-in-out"
          >
            <Link to={partner.link} rel="noopener noreferrer">
              <img
                src={partner.image}
                alt={partner.name}
                className="mx-auto rounded-full hover:-mt-1 border-[1px] border-pink-500 mb-2 w-[250px] h-[250px] transition-all duration-500 ease-in-out"
              />
            </Link>
            <Link
              to={partner.link}
              className="mt-2"
              rel="noopener noreferrer"
            >
              <h4 className="font-bold mt-2 text-pink-500 text-[32px]">{partner.name}</h4>
            </Link>
            <p className="mt-2">{partner.position}</p>
          </div>
        ))}
      </Slider>
      {/* Right Arrow */}
      <button
        className="absolute text-3xl hover:bg-pink-600 bg-pink-400 shadow-green-600 transition-all ease-in-out duration-200 right-0 top-1/2 transform -translate-y-1/2 z-10  p-2 rounded-full shadow-md"
        onClick={() => sliderRef.current.slickNext()}
      >
        <FaAngleRight />
      </button>
    </div>
  );
};

export default PartnerCarousel;