import React, { useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import "./HeaderCarousel.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import ListAnimation from './ListAnimation';
import TypingAnimation from './TypingAnimation';
import { Link } from 'react-router-dom';
import BgImageHome from '../../../images/back1.jpg'


const HeaderCarousel = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    let timer;
    if (isPlaying) {
      timer = setTimeout(() => {
        setIsPlaying(false);
      }, 7000);
    }
    return () => clearTimeout(timer);
  }, [isPlaying]);

  const handleSlideChange = () => {
    setIsPlaying(true); // Show loader on slide change
  };

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        // pagination={{ clickable: true }}
        autoplay={{ delay: 7000, disableOnInteraction: false }}
        loop={true}
        onSlideChange={handleSlideChange} // Trigger loader when slide changes
      >
        <SwiperSlide>
          <header>
          {/* <img src={BgImageHome} className='w-full h-[100vh] absolute ' alt="" /> */}
            <div className="h-container bg-transparent  text-white flex items-center justify-center">
              <div className="">
                <TypingAnimation />
                <h1 className="anim mt-3">Innovative IT Solutions</h1>
                <h1 className="anim anim1">for Modern Businesses</h1>
                <div className="cta anim">
                  <Link className="book-call" to="/Contact">
                    Book A Call
                  </Link>
                  <Link to="/About" className="learn-more anim transition-all duration-300 ease-in-out">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="h-image"></div>
          </header>
        </SwiperSlide>

        <SwiperSlide>
          <ListAnimation />
        </SwiperSlide>
      </Swiper>

      {/* Custom Navigation with Loader */}
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next">
        {isPlaying && (
          <div className="loader-circle">
            <svg className="loader-svg" viewBox="0 0 36 36">
              <path
                className="loader-circle-bg"
                d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path
                className="loader-circle-progress"
                d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                strokeDasharray="100, 100"
              />
            </svg>
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderCarousel;