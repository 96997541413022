import React, { useEffect, useState } from "react";
import "./Header CSS/Header.css";
import logo from "../../../images/Logo.png";
import { getUpdateOrDeleteProfile } from "../../../services/EtplApi";
import { motion } from "framer-motion";
import { User, Lock } from "lucide-react";
import { Link, useNavigate } from 'react-router-dom';
import { BiLogOut } from "react-icons/bi";

export default function Header() {
  const [profilePicture, setProfilePicture] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleLogout = () => {
    // Clear localStorage items
    localStorage.removeItem("access_token");
    localStorage.removeItem("UserId");
    localStorage.removeItem("is_superuser");

    // Redirect to the ETPL_Admin_Login page
    navigate("/ETPL_Admin_Login"); // Use navigate instead of history.push
  };

  useEffect(() => {
    // Fetch the profile picture when the component mounts
    const fetchProfilePicture = async () => {
      try {
        // Get the user ID from local storage
        const userId = localStorage.getItem("UserId"); // Ensure the key matches what you use in your app
        if (!userId) {
          console.error("User ID not found in local storage");
          return;
        }

        // Fetch the profile data using the user ID
        const response = await getUpdateOrDeleteProfile(userId, "GET");
        // Assuming the API response has a field called 'profile_picture'
        setProfilePicture(response.data.profile_picture); // Adjust based on your API response structure
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };

    fetchProfilePicture();
  }, []);

  return (
    <div className="h-[11vh] bg-red-300">
      <header className="w-full top-0 z-50 fixed h-[11vh] flex justify-between items-center border-b-2 border-pink-600">
        <div>
          <img src={logo} alt="Logo" className="h-8 w-auto" />
        </div>

        <div className="flex-1 flex justify-center">
          <h1 className="text-pink-600 font-bold lg:text-3xl animated-gradient md:text-xl xl:text-4xl">
            ETPL DASHBOARD
          </h1>
        </div>

        <div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="focus:outline-none"
          >
            <img
              src={profilePicture}
              alt="Profile"
              className="rounded-full md:h-12 md:w-12 lg:h-16 lg:w-16 border-2 border-gray-300 hover:border-gray-500 transition"
            />
          </button>

          {/* Dropdown */}
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: -5 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: -5 }}
              className="absolute right-0 mt-2 w-[230px] bg-gray-600 text-white shadow-lg rounded-lg border border-gray-200"
            >
              <ul className="py-2">
                <Link to="/admin/Admin_Profile_page">
                  <li className="flex items-center px-4 py-2 hover:bg-gray-100 hover:text-red-600 font-bold cursor-pointer">
                    <User size={18} className="mr-2 text-red-600   font-bold" />
                    View Profile
                  </li>
                </Link>
                <Link to="/ETPL_Change_Password">
                  <li className="flex items-center px-4 py-2 hover:bg-gray-100 hover:text-red-600 font-bold cursor-pointer">
                    <Lock size={18} className="mr-2 text-red-600 font-bold" />
                    Change Password
                  </li>
                </Link>
                <Link to="" onClick={handleLogout}>
      <li className="flex items-center px-4 py-2 hover:bg-gray-100 hover:text-red-600 font-bold cursor-pointer">
        <BiLogOut size={18} className="mr-2 text-red-600 font-bold" />
        Log Out
      </li>
    </Link>
              </ul>
            </motion.div>
          )}
        </div>
      </header>
    </div>
  );
}
