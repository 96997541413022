import React from "react";
import { useEffect } from "react";
import "./AboutCss/About.css";
import AOS from "aos";
import "aos/dist/aos.css";
import AboutImage from "../../images/remove.png";
import { FaRegLightbulb } from "react-icons/fa";
import { CgCheck } from "react-icons/cg";
import { GiBurningEye } from "react-icons/gi";
import { GiFoundryBucket } from "react-icons/gi";
import { GrUserExpert } from "react-icons/gr";
import tech from "../../images/tech.png";
import soft from "../../images/soft.png";
import time from "../../images/time.png";
import creative from "../../images/creative.png";
import adaptability from "../../images/adaptability.png";
import details from "../../images/details.png";
import critical from "../../images/critical.png";
import teamwork from "../../images/teamwork.png";
import App from "./PartnerCarousel/App";
import CoreValues from "./CoreValues";
import CanvasCursor from "../../components/CustomCursor/CanvasCursor";

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className=" w-full h-full relative">
      {/* Hero  */}
      <CanvasCursor />

      <video
        className="w-full h-screen object-cover -z-10 absolute top-0 left-0"
        autoPlay
        muted
        loop
      >
        <source src="/AboutUs.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="h-[85vh] pt-20 text-white  ">
        <div className=" flex items-center justify-center p-3  ">
          <div className=" flex w-full items-center justify-center">
            <h1 className=" text-[23px] whitespace-nowrap sm:text-[45px] md:text-[57px] mt-5 font-mono awesome anim">
              <span>J</span>
              <span>O</span>
              <span>I</span>
              <span>N</span>
              <span>-</span>
              <span>O</span>
              <span>U</span>
              <span>R</span>
              <span>-</span>
              <span>T</span>
              <span>E</span>
              <span>A</span>
              <span>M</span>
            </h1>
          </div>
        </div>
        <div className="mx-auto container max-w-[700px]  text-center">
          <p className="text-xl md:text-4xl font-serif font-medium anim">
            Empowering businesses to thrive in the digital world
          </p>
        </div>
        <div className="mx-auto container max-w-[700px] text-center ">
          <p className="text-xl md:text-4xl font-serif font-medium anim">
            through innovative website marketing solutions.
          </p>
        </div>
      </div>

      {/* IT SOlution */}
      <div className="bg-black w-full min-h-[600px] text-white lg:flex items-center justify-center gap-5">
        <div className="w-full p-10 ml-2 items-center">
          <div className="flex items-center justify-center pt-2">
            <h1 className="lg:text-4xl font-serif slide_down text-pink-600">
              SAAS SOLUTIONS
            </h1>
          </div>
          <div className="items-center">
            <p className="p-4 text-md text-justify slide_right anim1">
              Welcome to EmayamTech, where innovation meets excellence in SaaS
              solutions. We specialize in delivering cutting-edge software
              products designed to streamline your business operations and drive
              growth. Our flagship SaaS platform offers a suite of powerful
              tools, including project management, customer relationship
              management (CRM), and data analytics, all seamlessly integrated
              into one intuitive interface.
            </p>
            <p className="text-md p-4 slide_left text-justify anim1">
              At EmayamTech, we are committed to empowering businesses with
              scalable, cloud-based solutions that enhance productivity and
              efficiency. Our team of skilled developers, product managers, and
              data scientists work tirelessly to ensure our platform evolves
              with your needs, providing you with the tools to stay ahead in a
              competitive market.
            </p>
            <p className="text-md p-4 text-justify anim anim1">
              Join us on a journey of innovation and success. Discover the
              future of business technology with EmayamTech, where your vision
              meets our expertise to create extraordinary SaaS solutions. Let's
              build something exceptional together.
            </p>
          </div>
        </div>
        <div className="w-full flex justify-center move-anim">
          <img
            src={AboutImage}
            alt="SaaS Platform Overview"
            className="object-cover bg-blend-multiply slide_right anim1"
          />
        </div>
      </div>

      {/* Why choose us */}

      <div>
        <h1 className="text-[32px] mt-5 p-3 text-center text-pink-600">
          CORE VALUES
        </h1>
        <CoreValues />
      </div>
      <div className="bg-black w-full h-full p-10 text-white">
        <div className="flex justify-center items-center">
          <h1 className="lg:text-5xl capitalize anim anim1 text-pink-600">
            WHY CHOOSE US
          </h1>
        </div>
        <div className="lg:flex  justify-between mt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 w-full h-full cursor-pointer anim2 anim">
          <div className="background w-full h-full mt-2 cursor-pointer p-2 border rounded-[25px]">
            <FaRegLightbulb className="mt-3 text-6xl border-4 rounded-full p-2 " />

            <div className="flex items-center justify-center">
              <h1 className="text-xl font-bold  font-serif ">Mission</h1>
            </div>
            <div className="flex items-center justify-center">
              <h1 className=" text-xl font-bold p-1 font-serif ">
                Empowering Tomorrow
              </h1>
            </div>
            <div className="flex  items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">
                Empowering Visionary Entrepreneurial Innovation.
              </p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">Empower Future Leaders.</p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">
                Catalyst for Change,Tomorrow.
              </p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">
                Focus on providing resources.
              </p>
            </div>
            {/* <div className=' rounded-full border-none text-center p-2 mt-2 bg-[#a53892] hover:bg-gradient-to-t  from-cyan-500 to-blue-600 '>
                    View More
                 </div> */}
          </div>

          <div className=" background w-full h-full mt-2 cursor-pointer p-2 border rounded-[25px]">
            <GiBurningEye className="mt-3 text-6xl border-4 rounded-full p-2 " />

            <div className="flex items-center justify-center ">
              <h1 className="text-xl font-bold  font-serif ">Vision</h1>
            </div>
            <div className="flex items-center justify-center">
              <h1 className=" text-xl font-bold p-1 font-serif">
                Inspiring Tomorrow
              </h1>
            </div>
            <div className="flex  items-center">
              <CgCheck className="text-4xl items-center " />
              <p className="text-md font-medium">
                Dreaming, Innovating, Leading, Transforming
              </p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">Guiding Progress.</p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">Bold Vision,Bright Future.</p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">Creating Futures.</p>
            </div>
            {/* <div className=' rounded-full border-none text-center p-2 mt-2 bg-[#a53892] hover:bg-gradient-to-t  from-cyan-500 to-blue-600 '>
                    View More
                 </div> */}
          </div>

          <div className=" background w-full h-full mt-2 cursor-pointer p-2 border rounded-[25px]">
            <GiFoundryBucket className="mt-3 text-6xl border-4 rounded-full p-2 " />

            <div className="flex items-center justify-center ">
              <h1 className="text-xl font-bold  font-serif ">Foundation</h1>
            </div>
            <div className="flex items-center justify-center">
              <h1 className=" text-xl font-bold p-1 font-serif">
                Building Stronger
              </h1>
            </div>
            <div className="flex  items-center">
              <CgCheck className="text-4xl items-center " />
              <p className="text-md font-medium">
                Empowering Lives, Creating Hope.
              </p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">
                Building Tomorrow, Today’s Foundations.
              </p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">
                Solid Principles, Lasting Impact.
              </p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">
                Visionary Ideas, Strong Ethics, Commitment to excellence
              </p>
            </div>
            {/* <div className=' rounded-full border-none text-center p-2 mt-2 bg-[#a53892] hover:bg-gradient-to-t  from-cyan-500 to-blue-600 '>
                 View More
              </div> */}
          </div>
          {/* 
          <div className=' background w-full h-full  mt-2 cursor-pointer p-2 border rounded-[25px]'>
              
                   <GiFoundryBucket className='mt-3 text-6xl border-4 rounded-full p-2'/>
                 
                 <div className='text-center'>
                    <h1 className='text-xl font-bold  font-serif '>Foundation</h1>
                 </div>
                 <div className='text-center'>
                   <h1 className=' text-xl font-bold p-1 font-serif'>Building Stronger</h1>
                 </div>
                 <div className='flex items-center'>
                    <CgCheck className='text-4xl items-center ' />
                    <p className='text-md font-medium'>Empowering Lives, Creating Hope.</p>
                 </div>
                 <div className='flex items-center'>
                     <CgCheck className='text-4xl' />
                      <p className='text-md font-medium'>Building Tomorrow, Today’s Foundations.</p>
                 </div>
                 <div className='flex items-center'>
                     <CgCheck className='text-4xl' />
                      <p className='text-md font-medium'>Solid Principles, Lasting Impact.</p>
                 </div>
                 <div className='flex items-center'>
                     <CgCheck className='text-4xl' />
                      <p className='text-md font-medium'>Visionary Ideas, Strong Ethics.</p>
                 </div>
                 <div className=' rounded-full border-none text-center p-2 mt-2 bg-[#a53892] hover:bg-gradient-to-t  from-cyan-500 to-blue-600 '>
                    View More
                 </div>
          </div> */}

          <div className=" background w-full h-full mt-2 cursor-pointer p-2 border rounded-[25px]">
            <GrUserExpert className="mt-3 text-6xl border-4 rounded-full p-2 " />

            <div className="flex items-center justify-center">
              <h1 className="text-xl font-bold  font-serif ">Expertise</h1>
            </div>
            <div className="flex items-center justify-center">
              <h1 className=" text-xl font-bold p-1 font-serif">
                Masters of our Craft
              </h1>
            </div>
            <div className="flex  items-center">
              <CgCheck className="text-4xl items-center " />
              <p className="text-md font-medium">
                Innovation, Proficiency, Mastery, Excellence.
              </p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">
                Cutting-edge Technology Mastery.
              </p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">Proven Strategic Guidance.</p>
            </div>
            <div className="flex items-center">
              <CgCheck className="text-4xl" />
              <p className="text-md font-medium">
                Unwavering Commitment to Excellence.
              </p>
            </div>
            {/* <div className=' rounded-full border-none text-center p-2 mt-2 bg-[#a53892] hover:bg-gradient-to-t  from-cyan-500 to-blue-600 '>
                    View More
                 </div> */}
          </div>
        </div>
      </div>

      {/* Our Partners */}

      <div className="part-box line-height text-white">
        <h1 data-aos="fade-up" className="text-pink-600">
          OUR LEADERSHIP TEAM
        </h1>
        <App />
      </div>
      {/* Our Company Skills */}

      <div className="  w-full h-full p-10">
        <div className=" mx-auto container">
          <div className="flex items-center justify-center">
            <h1 className="lg:text-5xl font-bold text-pink-600 anim3 anim">
              OUR COMPANY SKILLS
            </h1>
          </div>
        </div>
        <div className="mt-10 w-full h-full p-5 px-3 lg:flex anim anim4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 w-full cursor-pointer  ">
            <div className="card flex-col p-5 rounded-md ">
              <div className="flex items-center justify-center ">
                <img src={tech} alt="" width={100} className="move-anim" />
              </div>
              <h1 className="text-center text-xl font-serif mt-10 text-black">
                Technical Skills
              </h1>
              <div className="">
                <p className="text-center text-md mt-2 text-black">
                  Proficiency in specific technical areas relevant to the job or
                  industry.
                </p>
              </div>
            </div>
            <div className=" card flex-col p-5 rounded-md">
              <div className="flex items-center justify-center">
                <img src={soft} alt="" width={100} className="move-anim" />
              </div>
              <h1 className="text-center text-xl font-serif mt-10 text-black">
                Communication Skills
              </h1>
              <div className="">
                <p className="text-center text-md mt-2 text-black">
                  Effective verbal and written communication.
                </p>
              </div>
            </div>
            <div className=" card flex-col p-5  rounded-md">
              <div className="flex items-center justify-center">
                <img src={time} alt="" width={100} className="move-anim" />
              </div>
              <h1 className="text-center text-xl font-serif mt-10 text-black">
                Time Management
              </h1>
              <div className="">
                <p className="text-center text-md  mt-2 text-black">
                  {" "}
                  Efficiently managing time and prioritizing tasks.
                </p>
              </div>
            </div>
            <div className="card flex-col p-5  rounded-md">
              <div className="flex items-center justify-center">
                <img src={creative} alt="" width={100} className="move-anim" />
              </div>
              <h1 className="text-center text-xl font-serif  mt-10 text-black">
                Creativity
              </h1>
              <div className="">
                <p className="text-center text-md mt-2 text-black">
                  Ability to think creatively and generate innovative ideas.
                </p>
              </div>
            </div>
            <div className="card flex-col p-5  rounded-md">
              <div className="flex items-center justify-center">
                <img
                  src={adaptability}
                  alt=""
                  width={100}
                  className="move-anim"
                />
              </div>
              <h1 className="text-center text-xl font-serif mt-10 text-black">
                Adaptability
              </h1>
              <div className="">
                <p className="text-center text-md mt-2 text-black">
                  {" "}
                  Flexibility to adapt to changing circumstances and
                  environments.
                </p>
              </div>
            </div>
            <div className="card flex-col p-5  rounded-md">
              <div className="flex items-center justify-center">
                <img src={details} alt="" width={100} className="move-anim" />
              </div>
              <h1 className="text-center text-xl font-serif mt-10 text-black">
                Attention to Detail
              </h1>
              <div className="">
                <p className="text-center text-md mt-2 text-black">
                  Thoroughness and accuracy in completing tasks.
                </p>
              </div>
            </div>

            <div className="card flex-col p-5 rounded-md">
              <div className="flex items-center justify-center">
                <img src={critical} alt="" width={100} className="move-anim" />
              </div>
              <h1 className="text-center text-xl font-serif mt-10 text-black">
                Critical Thinking
              </h1>
              <div className="">
                <p className="text-center text-md mt-2 text-black">
                  Ability to analyze information and make reasoned judgments.
                </p>
              </div>
            </div>

            <div className="card flex-col p-5 rounded-md">
              <div className="flex items-center justify-center">
                <img src={teamwork} alt="" width={100} className="move-anim" />
              </div>
              <h1 className="text-center text-xl font-serif mt-10 text-black">
                Collaboration
              </h1>
              <div className="">
                <p className="text-center text-md mt-2 text-black">
                  Working effectively with others in a team setting.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
