import React, { useState } from 'react';
import LogoImage from '../../../images/Logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { loginUser } from "../../../services/EtplApi";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate()


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await loginUser(formData);
      localStorage.setItem("access_token", response.data.access);
      localStorage.setItem("UserId", response.data.user_id);
      localStorage.setItem("is_superuser", response.data.is_superuser);
      setSuccessMessage('Login successful! Redirecting...');
      setTimeout(() => {
        navigate("/admin/DashBoard");
      }, 2000);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'An error occurred during login. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='bg-black h-[100vh] w-full flex items-center justify-center flex-col gap-10'>
      <div>
        <img src={LogoImage} alt="Logo" className='w-[280px] md:w-[320px]' />
      </div>
      <div>
        <form onSubmit={handleSubmit} className='bg-white border-t-4 border-t-red-500 border-b-4 border-b-red-600 p-3 rounded-md w-[300px] sm:w-[350px] md:w-[450px] '>
          {/* Display success or error messages */}
          {successMessage && (
            <div className="bg-green-100 border text-center border-green-400 text-green-700 px-4 py-3 rounded mb-4">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="bg-red-100 border text-center border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {errorMessage}
            </div>
          )}

          <h1 className='text-pink-600 text-[23px] mt-1 text-center tracking-wider font-bold font-serif'>Admin Login</h1>
          <div>
            <div className='flex-col flex mt-3 gap-2'>
              <label htmlFor="username" className='text-pink-800 font-serif'>User Name :</label>
              <input
                type="text"
                id="username"
                name="username"
                placeholder='Enter Your User Name'
                value={formData.username}
                onChange={handleInputChange}
                className='bg-pink-200 required font-serif w-full p-2 rounded'
                required
              />
            </div>
            <div className='flex-col flex mt-2 gap-2 relative'>
              <label htmlFor="password" className='text-pink-800 font-serif'>Password :</label>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder='Enter Your Password'
                value={formData.password}
                onChange={handleInputChange}
                className='bg-pink-200 required font-serif w-full p-2 rounded pr-10'
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className='absolute right-4 text-[20px] top-[53px] text-black'
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
              <Link to="/ETPL_Forgot_Password" className='text-blue-600 font-serif text-[16px] hover:text-blue-800 right-0 absolute top-4 transition-all ease-in-out duration-300'>Forgot Password</Link>
            </div>
            <div className='mt-6 flex items-center justify-center'>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`bg-red-400 px-5 py-2 font-serif font-bold rounded-lg transition-all duration-300 ease-in-out ${
                  isSubmitting ? 'bg-gray-400 text-white cursor-not-allowed' : 'hover:bg-pink-700'
                }`}
              >
                {isSubmitting ? 'Logging in...' : 'Login'}
              </button>
            </div>
          </div>
          {/* <div className='mt-4'>
            <p className='text-black text-center font-serif'>
              Don't Have an Account?{' '}
              <Link to="/ETPL_Admin_register" className='underline text-blue-400 transition-all duration-300 ease-in-out hover:text-blue-800'>
                Register
              </Link>
            </p>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default Login;